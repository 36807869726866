import React, { useState, useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Grid, TextField, Typography } from '@mui/material';
import { getDialogLoadingState, getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import TasksTable from '../../../components/Dashboard/ManageTasks/TasksTable';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getModifyValue, setModify } from '../../../app/slices/modifySlice';
import * as TaskServices from "../../../services/task-services.proxy";
import * as UserServices from "../../../services/user-services.proxy";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import Message from '../../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import TaskCard from '../../../sharedComponents/ui/TaskCard';
import Sun from '@mui/icons-material/WbSunnyOutlined';
import Fire from '@mui/icons-material/LocalFireDepartmentOutlined';
import Calendar from '@mui/icons-material/EventRepeatOutlined';
import Bookmark from '@mui/icons-material/BookmarkAddedOutlined';
import CreateTask from '../../../components/Dashboard/ManageTasks/CreateTask';
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import * as userRolesConst from "../../../user-roles-consts";
import FilterTask from '../../../components/Dashboard/ManageTasks/FilterTask';
import * as ReminderServices from "../../../services/reminder-services.proxy";

const getTaskList = TaskServices.getTaskList;
const getUserProfile = UserServices.getUserProfile;
const addTask = TaskServices.addTask;
const getTaskInputData = TaskServices.getTaskInputData;
const openOrCompleteTask = TaskServices.openOrCompleteTask;

const postReminder = ReminderServices.postReminder;
const inActiveReminder = ReminderServices.inActiveReminder;

const ManageTasks = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = useAppSelector((state) => state.userData.token);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const intl = useIntl();

  //select states 
  const modify = useAppSelector(getModifyValue);
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const loading = useAppSelector(getLoadingState);
  const callStatus = useAppSelector(getCallStatus);
  const [initiallistLoading, setInitialListLoading] = useState(false);
  const [inputDataLoading, setInputDataLoading] = useState(false);
  const [openOrCompleteLoading, setOpenOrCompleteLoading] = useState(false);
  const userRoles = useAppSelector(selectRoles);
  const userType = useAppSelector(selectUserType);

  //Data to fill 
  const [userData, setUserData] = useState<any>();
  const [tasksTable, setTasksTable] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [assigneesList, setAssigneesList] = useState<any>();
  const [internalAssigneesList, setInternalAssigneesList] = useState([]);
  const [externalAssigneesList, setExternalAssigneesList] = useState([]);
  const [clientsList, setClientsList] = useState<any>();
  const [typesList, setTypesList] = useState<any>();
  const [searchTypesList, setSearchTypesList] = useState<any>();
  const [moduleList, setModuleList] = useState<any>();
  const [statusesList, setStatusesList] = useState<any>();
  const [recurringPeriodsList, setRecurringPeriodsList] = useState<any>();
  const [remindersList, setRemindersList] = useState<any>();

  //Add Task
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
  const [taskObject, setTaskObject] = useState({
    title: "",
    dueDate: "",
    description: "",
    status: 1,
    type: 1,
    module: 1,
    clientId: localStorage.getItem('userType') === '1' ? "" : null,
    assigneeId: "",
    priority: 3,
    recurringPeriod: 0,
    note: ""
  })

  //table filter states
  const [search, setSearch] = useState<string>();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  const [filterDialog, setFilterDialog] = useState(false);
  // const [pageNb, setPageNb] = useState(1);
  // const [pageSize, setPageSize] = useState(5);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [last30days, setLast30days] = useState(false);
  const [dueDate, setDueDate] = useState('');

  //total
  const [newTasks, setNewTasks] = useState(0);
  const [openTasks, setOpenTasks] = useState(0);
  const [overdueTasks, setOverdueTasks] = useState(0);
  const [doneTasks, setDoneTasks] = useState(0);

  const [isOpenCardSelected, setIsOpenCardSelected] = useState(false);
  const [openStatusesIds, setOpenStatusesIds] = useState([1, 2, 3, 4, 6, 7])

  const [filterPayload, setFilterPayload] = useState({
    startDate: null,
    endDate: null,
    clientId: null,
    typeId: null,
    module: null,
    searchType: 0
  });
  const [selectedClient, setSelectedClient] = useState(filterPayload.clientId);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [selectedType, setSelectedType] = useState(filterPayload.typeId);
  const [selectedSearchType, setSelectedSearchType] = useState(filterPayload.searchType);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedModule, setSelectedModule] = useState(filterPayload.module);
  const [filterSearch, setFilterSearch] = useState('');

  const [openCustomReminder, setOpenCustomReminder] = useState(false);


  const getUserProfileCall = () => {
    dispatch(setDialogLoading(true));
    getUserProfile(token).then((x) => {
      setUserData(x);
      dispatch(setDialogLoading(false));
    })
  }
  const listData =
  {
    searchTerm: search ? search : null,
    pageNumber: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    statusId: selectedStatus.length > 0 ? selectedStatus : null,
    last30days: last30days,
    startDate: filterPayload?.startDate || null,
    endDate: filterPayload?.endDate || null,
    clientId: filterPayload?.clientId || selectedClient || null,
    assigneeId: selectedAssignee || null,
    // clientId: selectedClient || null,
    // typeId: filterPayload?.typeId,
    //typeId: selectedType || null,
    dueDate: dueDate ? dueDate : null,
    recurringPeriod: selectedPeriod || null,
    module: selectedModule || null,
    searchType: filterPayload.searchType,
  }
  const getTaskListCall = () => {
    dispatch(setDialogLoading(true))
    setInitialListLoading(true);
    getTaskList(listData).then((x) => {
      setTasksTable(x.tasks);
      setDataLength(x.total || 0);
      setNewTasks(x.totalTasks?.new);
      setOpenTasks(x.totalTasks?.open);
      setOverdueTasks(x.totalTasks?.overdue);
      setDoneTasks(x.totalTasks?.done);
      dispatch(setDialogLoading(false));
      setInitialListLoading(false);
    });
  }

  const addTaskCall = (taskObject: {}, buttonNb: any) => {
    dispatch(setLoading(true));
    addTask(taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          if (buttonNb === 1) {
            setOpenAddTaskDialog(false);
            dispatch(setModify(!modify));
          }
          if (buttonNb === 2) navigate(`/task-details?id=${x}`)

        }, 1000);
      }
      dispatch(setLoading(false));
    })

  }

  const getTaskInputDataCall = () => {
    setInputDataLoading(true);
    getTaskInputData().then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        setAssigneesList(x.users);
        setInternalAssigneesList(x.internalUsers);
        if (userType === 2) setExternalAssigneesList(x.externalUsers);
        setClientsList(x.clients);
        setStatusesList(x.statuses);
        setTypesList(x.types);
        setSearchTypesList(x.searchTypes);
        setRemindersList(x.reminderTypes);
        setModuleList(x.modules);
        setRecurringPeriodsList(x.recurringPeriods);
        getTaskListCall();

        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      setInputDataLoading(false);
    })
  }

  const openOrCompleteTaskCall = (taskId: string) => {
    setOpenOrCompleteLoading(true)
    openOrCompleteTask(taskId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
          dispatch(setModify(!modify));
        }, 1500);

      }
      setOpenOrCompleteLoading(false)
    })
  }

  const postReminderCall = (reminderObj: any, reminderId: any) => {

    if (reminderId) {

      if (reminderObj.typeId === 4) dispatch(setLoading(true))
      else dispatch(setDialogLoading(true))
      dispatch(setActionCallFrom('post reminder'))
      inActiveReminder(reminderId).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
          }, 4500);

        }
        else {
          if (reminderObj.typeId === 4) dispatch(setLoading(true))
          else dispatch(setDialogLoading(true))
          dispatch(setActionCallFrom('post reminder'))
          postReminder(reminderObj).then((x) => {
            if (x.ErrorMessage) {
              dispatch(setErrorMsg(x.ErrorMessage));
              setTimeout(() => {
                dispatch(setErrorMsg(""));
                dispatch(setActionCallFrom(''))
              }, 4500);

            }
            else {
              setOpenCustomReminder(false);
              dispatch(setModify(!modify));
              dispatch(setActionCallFrom(''))
            }
            dispatch(setLoading(false))
            dispatch(setDialogLoading(false))

          })


        }
      })


    }
    else {
      if (reminderObj.typeId === 4) dispatch(setLoading(true))
      else dispatch(setDialogLoading(true))
      dispatch(setActionCallFrom('post reminder'))
      postReminder(reminderObj).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
            dispatch(setActionCallFrom(''))
          }, 4500);

        }
        else {
          setOpenCustomReminder(false);
          dispatch(setModify(!modify));
          dispatch(setActionCallFrom(''))
        }
        if (reminderObj.typeId === 4) dispatch(setLoading(false))
        else dispatch(setDialogLoading(false))

      })
    }


  }

  const inActiveReminderCall = (reminderId: string) => {
    dispatch(setDialogLoading(true))
    inActiveReminder(reminderId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        dispatch(setModify(!modify));

      }
      dispatch(setDialogLoading(false))
    })

  }

  useEffect(() => {
    getTaskListCall();
  }, [modify, search, selectedStatus, last30days, paginationModel, filterPayload, dueDate, selectedClient, selectedAssignee, selectedType, selectedPeriod, selectedModule]);

  useEffect(() => {
    getUserProfileCall();
    getTaskInputDataCall();
  }, []);

  useEffect(() => {

    let filtered = tasksTable
    if (selectedStatus.length > 0) {
      filtered = filtered.filter((task) => selectedStatus?.includes(task));
    }

    if (last30days) {
      filtered = filtered.filter((task) => {
        const taskCreationDate = new Date(task);
        return taskCreationDate.getTime() >= Date.now() - 30 * 24 * 60 * 60 * 1000;
      });
    }
    setFilteredTasks(filtered);

  }, [selectedStatus, last30days, tasksTable]);

  const handleCardClick = (status: number[], isOpenCard: boolean) => {
    if (isOpenCard) {
      setIsOpenCardSelected(!isOpenCardSelected);
      if (isOpenCardSelected) setSelectedStatus([])
      else setSelectedStatus(status)
    }
    else {
      setIsOpenCardSelected(false);
      if (selectedStatus.length === openStatusesIds.length && selectedStatus.every((value, index) => value === openStatusesIds[index]))
        setSelectedStatus(status)
      else {
        const removeAlreadySelectedRoles = selectedStatus.filter(i => !status.includes(i));
        const filterNewSelectedRoles = status.filter(i => !selectedStatus.includes(i));
        const combinedAr = [...removeAlreadySelectedRoles, ...filterNewSelectedRoles];
        setSelectedStatus(combinedAr)
      }
    }

    // setSelectedStatus((prevSelected = []) => {
    //   const isAlreadySelected = status.every(s => prevSelected.includes(s));
    //   if (isAlreadySelected) {
    //     return prevSelected.filter(s => !status.includes(s));
    //   } else {
    //     return [...prevSelected, ...status];
    //   }
    // });
  };

  const handleLast30DaysToggle = () => {
    setLast30days((prev) => !prev);
  };

  const handleFilterSubmit = (filterData: any) => {

    setSearch(filterData.search);
    setFilterPayload({
      ...filterData,
      clientId: filterData.clientId || null,
      typeId: filterData.typeId || null,
      module: filterData.module || null,
      searchType: filterData.searchType || 1
    });
    if (filterPayload.clientId) {
      setSelectedClient(filterData.clientId);
    } else {
      setSelectedClient(null);
    }
    if (filterPayload.typeId) {
      setSelectedType(filterData.typeId);
    } else {
      setSelectedType(null);
    }
    if (filterPayload.module) {
      setSelectedModule(filterData.module)
    } else {
      setSelectedModule(null);
    }
    if (filterPayload.searchType) {
      setSelectedSearchType(filterData.searchType)
    } else {
      setSelectedSearchType(1);
    }
    dispatch(setModify(!modify));
  };

  useEffect(() => {
    if (filterPayload.clientId !== undefined) {
      setSelectedClient(filterPayload.clientId);
    }
    if (filterPayload.typeId !== undefined) {
      setSelectedType(filterPayload.typeId);
    }
    if (filterPayload.module !== undefined) {
      setSelectedModule(filterPayload.module);
    }
  }, [filterPayload]);

  useEffect(() => {
    console.log('Rows info are', tasksTable);
  }, [tasksTable])
  return (
    <>
      <Grid container direction='column' rowGap={1} width='100%'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoading || initiallistLoading || inputDataLoading || openOrCompleteLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container direction="column" spacing={1} sx={{ width: '100%', p: 1 }}>
          <Grid item>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', display: 'flex', gap: '8px' }}>
              {!initiallistLoading && userData?.username && (
                <>
                  <Message id="text.hello" style={{ fontWeight: 'bold', fontSize: '24px', lineHeight: 'initial' }} /> {userData.username}!
                </>
              )}
            </Typography>
          </Grid>
          <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} marginBottom={1.5}>
            <HomeOutlinedIcon style={{ color: '#93919A' }} /> <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="task.tasks" className="Field-input desktop-paragraph font-weight-400" />
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.open-tasks' })} count={openTasks} icon={Sun} gradientColors={['#FBE6B1', '#F9F1DA']} backGroundColor={['#FBE6B1', '#F9F1DA']} status={[1, 2, 3, 4, 6, 7]} onCardClick={handleCardClick} isSelected={isOpenCardSelected} isOpenCard={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.overdue-tasks' })} count={overdueTasks} icon={Fire} gradientColors={['#F7E6D9', '#F7E6D9']} backGroundColor={['#F17D72', '#F9C69F']} status={[3]} onCardClick={handleCardClick} isSelected={selectedStatus.includes(3) && !isOpenCardSelected} isOpenCard={false} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.new-tasks' })} count={newTasks} icon={Calendar} gradientColors={['#C8EBFF', '#69BAE8']} backGroundColor={['#C8EBFF', '#69BAE8']} status={[1]} onCardClick={handleCardClick} isSelected={selectedStatus.includes(1) && !isOpenCardSelected} isOpenCard={false} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TaskCard title={intl.formatMessage({ id: 'task.done-tasks' })} count={doneTasks} icon={Bookmark} gradientColors={['#CCF0EB', '#00B69B']} backGroundColor={['#CCF0EB', '#99C1BB']} status={[5]} onCardClick={handleCardClick} isSelected={selectedStatus.includes(5) && !isOpenCardSelected} isOpenCard={false} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className='desktop-header-5'><Message id="task.my-tasks" className='desktop-header-5 font-weight-400 Field-input' /></Grid>
        <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >

          <Grid item container marginTop={2} alignItems='center'>

            {errorMsg ?
              <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
              <></>
            }
          </Grid>
          <Grid item container bgcolor='#FEFEFF' borderRadius='8px' boxShadow='0px 2px 4px 0px #0000001F' padding={1}>
            <Grid item container direction='row' columnGap={2} padding={1}>
              <Grid item xs={5}>
                <TextField
                  value={search}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                    style: {
                      borderRadius: '35px',
                      fontFamily: 'DM Sans',
                      height: '48px',
                      backgroundColor: '#F7F9FF',
                      border: '1px solid #E6E6E8'
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder={intl.formatMessage({ id: 'text.search' })}
                  onChange={(e) => {
                    setFilterPayload({ ...filterPayload, searchType: 1 });
                    setSearch(e.target.value)
                  }
                  }
                />
              </Grid>
              <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setSearch('');
                  setLast30days(false);
                  setSelectedStatus([]);
                  setFilterPayload({ startDate: null, endDate: null, clientId: null, typeId: null, module: null, searchType: 0 });
                  setDueDate('');
                  setSelectedClient(null);
                  setSelectedAssignee(null);
                  setSelectedType(null);
                  setSelectedModule(null);
                  setSelectedPeriod(null);
                  dispatch(setModify(!modify))
                }
                }><RestartAltIcon htmlColor='#C3C2C7' />
              </Grid>
              {/* <Grid item width='140px'>
                <button className='greyButton'
                  style={{ backgroundColor: last30days ? "#C8EBFF" : "", height: '48px' }}
                  onClick={handleLast30DaysToggle}
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <CalendarTodayOutlinedIcon fontSize="small" color="disabled" />
                    <Message id="task.last-30-days" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid> */}
              <Grid item width='100px'>
                <button className='greyButton'
                  style={{ height: '48px' }}
                  onClick={() => {
                    setFilterDialog(true);
                  }}
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <FilterAltOutlinedIcon fontSize="small" />
                    Filter
                  </Grid>

                </button>
              </Grid>
              {/* <Grid item width='120px'>
                <button className='greyButton'
                //   onClick={() => {
                //    
                //   }
                //   }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center' style={{ color: '#93919A' }}>
                    <ViewWeekOutlinedIcon fontSize="small" />
                    <Message id="task.columns" className='Field-input font-weight-700 labelslink neutral-3' />
                  </Grid>

                </button>
              </Grid> */}
              <Grid item width='172px'>
                <button className='blueButton'
                  style={{ height: '48px' }}
                  onClick={() => {
                    setOpenAddTaskDialog(true)
                  }
                  }
                  disabled={
                    (userType === 1 && !userRoles?.includes(userRolesConst.ADMIN_ID) && !userRoles?.includes(userRolesConst.TAX_ADVISOR_ID) && !userRoles?.includes(userRolesConst.CLERK_ID))
                  }
                >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                    <GroupAddOutlinedIcon />
                    <Message id="task.create-task" className='Field-input font-weight-700 labelslink offwhite-50' />
                  </Grid>

                </button>
              </Grid>
            </Grid>
            <TasksTable
              rows={tasksTable}
              dataLength={dataLength}
              setDataLength={setDataLength}
              modify={modify}
              setModify={setModify}
              open={openAddTaskDialog}
              setOpen={setOpenAddTaskDialog}
              statusesList={statusesList}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              dueDate={dueDate}
              setDueDate={setDueDate}
              typesList={typesList}
              moduleList={moduleList}
              assigneesList={assigneesList}
              internalAssigneesList={internalAssigneesList}
              externalAssigneesList={externalAssigneesList}
              setExternalAssigneesList={setExternalAssigneesList}
              clientsList={clientsList}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              // pageNb={pageNb}
              // setPageNb={setPageNb}
              // pageSize={pageSize}
              // setPageSize={setPageSize}
              recurringPeriodsList={recurringPeriodsList}
              taskObject={taskObject}
              setTaskObject={setTaskObject}
              openOrCompleteTaskCall={openOrCompleteTaskCall}
              remindersList={remindersList}
              postReminderCall={postReminderCall}
              inActiveReminderCall={inActiveReminderCall}
              setOpenCustomReminder={setOpenCustomReminder}
              openCustomReminder={openCustomReminder}
            />
          </Grid>
        </Grid>

        <CreateTask
          loading={loading}
          open={openAddTaskDialog}
          setOpen={setOpenAddTaskDialog}
          callStatus={callStatus}
          errorMsg={errorMsg}
          confirmAction={addTaskCall}
          assigneesList={assigneesList}
          clientsList={clientsList}
          statusesList={statusesList}
          typesList={typesList}
          moduleList={moduleList}
          recurringPeriodsList={recurringPeriodsList}
          internalAssigneesList={internalAssigneesList}
          externalAssigneesList={externalAssigneesList}
          setExternalAssigneesList={setExternalAssigneesList}
          taskObject={taskObject}
          setTaskObject={setTaskObject}
        />

        <FilterTask
          loading={loading}
          open={filterDialog}
          setOpen={setFilterDialog}
          callStatus={callStatus}
          errorMsg={errorMsg}
          confirmAction={handleFilterSubmit}
          clientsList={clientsList}
          selectedClientId={selectedClient}
          selectedTypeId={selectedType}
          selectedModule={selectedModule}
          typesList={typesList}
          searchTypesList={searchTypesList}
          moduleList={moduleList}
          search={filterSearch}
          setSearch={setFilterSearch}
          currentSearch={search}
          selectedSearchTypeId={selectedSearchType}
        />
      </Grid>

    </>
  );
}

export default ManageTasks;