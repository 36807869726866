import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Backdrop from '@mui/material/Backdrop';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDialogLoadingState, setDialogLoading } from '../../app/slices/loadingSlice';
import AddUser from '../../components/Dashboard/ManageUsers/AddUser';
import ReviewAndInvite from '../../components/Dashboard/ManageUsers/ReviewAndInvite';
import * as rolesServices from "../../services/roles-services.proxy";
import { useLocation } from 'react-router-dom';
import Message from '../../sharedComponents/ui/Message';
import * as UserRolesConsts from "../../user-roles-consts";
import * as userServices from "../../services/user-services.proxy";

const getUserRoles = rolesServices.getUserRoles;
const getUserData = userServices.getUserData

const AddUsers = () => {

  //query params
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const queryActiveTab = query.get('type');

  const [activeTab, setActiveTab] = useState<string>(queryActiveTab && parseInt(queryActiveTab) === 1 ? 'Tms' : 'Clients');
  const [activeSection, setActiveSection] = useState<string>('Add');


  const [addedTms, setAddedTms] = useState([{ id: 1, roles: [], email: "", accessModules: localStorage.getItem('userType') === '1' ? [] : null, modules: localStorage.getItem('userType') === '1' ? null : [] }]);
  const [addedClients, setAddedClients] = useState([{ id: 1, roles: [UserRolesConsts.CLIENT_ADMIN_ID], email: "", name: "", clientNumber: "", modules: [] }]);

  const [taxAdvisorClients, setTaxAdvisorClients] = useState([]);
  const [modules, setModules] = useState([]);

  const dispatch = useAppDispatch();

  const dialogLoading = useAppSelector(getDialogLoadingState);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    dispatch(setDialogLoading(true));
    getUserRoles().then((x) => {
      setRoles(x);
      dispatch(setDialogLoading(false));
    })

    getUserData().then((x) => {
      let tmpClients = x.clients.map((client: any) => ({
        ...client,
        expand: true
      }));
      setTaxAdvisorClients(tmpClients);
      setModules(x.modules)
    })
  }, [])


  return (
    <Grid container direction='column' rowGap={1} xs={12}>
      <Grid item container direction='column' width='100%'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item className='desktop-header-5'> <Message id="user.profile.add-user" className="Field-input desktop-header-5 font-weight-400" /></Grid>
        <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} >
          <HomeOutlinedIcon style={{ color: '#93919A' }} />  <Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.user-management" className="Field-input desktop-paragraph font-weight-400" /> / <Message id="user.profile.add-users" className="Field-input desktop-paragraph font-weight-400" />
        </Grid>
        <Grid item container direction='row' justifyContent='center' alignItems='center' columnGap={1}>
          <Grid item container direction='column' width='80px' rowGap={1} alignItems='center'>
            <Grid item className={activeSection === 'Add' ? 'activeCircle' : 'InactiveCircle'}>1</Grid>
            <Grid item className={activeSection === 'Add' ? 'iris-100' : 'black'}> <Message id="user.profile.add-user" /></Grid>
          </Grid>
          <Grid item className='blueLine'></Grid>
          <Grid item container direction='column' width='80px' rowGap={1} alignItems='center'>
            <Grid item className={activeSection === 'Review' ? 'activeCircle' : 'InactiveCircle'}>2</Grid>
            <Grid item className={activeSection === 'Review' ? 'iris-100' : 'black'}> <Message id="text.review" /></Grid>
          </Grid>
        </Grid>
        {localStorage.getItem('userType') === '1' ?
          <Grid item container direction='column' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} justifyContent='center' marginTop={1}>
            {activeSection === "Add" ?
              <Grid item container width='900px'>
                <Grid item container marginTop={2}>
                  <Grid item className={activeTab === 'Tms' ? 'activeTabs' : 'tabs'}
                    onClick={() => setActiveTab('Tms')}>
                    {/* <Message id="button.add-team-members" className="labelsLinks labels-extra-bold Field-input " /> */}
                    Add Users
                  </Grid>
                  <Grid item className={activeTab === 'Clients' ? 'activeTabs' : 'tabs'}
                    onClick={() => setActiveTab('Clients')}>
                    <Message id="button.add-clients" className="labelsLinks labels-extra-bold Field-input " />
                  </Grid>
                </Grid>
                <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F' marginBottom={10}>
                  <AddUser
                    activeTab={activeTab} setActiveSection={setActiveSection} addedTms={addedTms} setAddedTms={setAddedTms} roles={roles} addedClients={addedClients} setAddedClients={setAddedClients} taxAdvisorClients={taxAdvisorClients} modules={modules}
                    setTaxAdvisorClients={setTaxAdvisorClients} />
                </Grid>
              </Grid>
              :
              <Grid item container width='900px'>
                <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F' >
                  <ReviewAndInvite
                    addedTms={addedTms}
                    activeTab={activeTab}
                    roles={roles}
                    setActiveSection={setActiveSection}
                    addedClients={addedClients}
                    taxAdvisorClients={taxAdvisorClients}
                    modules={modules}
                  />
                </Grid>
              </Grid>
            }
          </Grid>
          :
          <Grid item container direction='column' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} justifyContent='center' marginTop={1}>
            {activeSection === "Add" ?
              <Grid item container width='900px'>
                <Grid item container marginTop={2}>
                  <Grid item className='activeTabs'>
                    <Message id="button.add-team-members" className="labelsLinks labels-extra-bold Field-input " />
                  </Grid>
                </Grid>
                <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F' marginBottom={10}>
                  <AddUser
                    activeTab={activeTab}
                    setActiveSection={setActiveSection}
                    addedTms={addedTms}
                    setAddedTms={setAddedTms}
                    roles={roles}
                    addedClients={addedClients}
                    setAddedClients={setAddedClients}
                    taxAdvisorClients={taxAdvisorClients}
                    modules={modules}
                    setTaxAdvisorClients={setTaxAdvisorClients} />
                </Grid>
              </Grid>
              :
              <Grid item container width='900px'>
                <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F' >
                  <ReviewAndInvite
                    addedTms={addedTms}
                    activeTab={activeTab}
                    roles={roles}
                    setActiveSection={setActiveSection}
                    addedClients={addedClients}
                    taxAdvisorClients={taxAdvisorClients}
                    modules={modules}
                  />
                </Grid>
              </Grid>
            }
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default AddUsers