import React, { useState, useCallback, useRef, useEffect } from "react";
import { atom, RecoilRoot, useRecoilState, useSetRecoilState } from 'recoil';
import {
  Grid,
  ClickAwayListener,
  Fade,
  List,
  ListItemButton,
  Paper,
  Box,
  TextField,
  CssBaseline,
  CircularProgress
} from "@mui/material";
import Message from '../../../sharedComponents/ui/Message';
import ProfileImage from "../../../sharedComponents/ui/ProfileImage";
import * as userServices from "../../../services/user-services.proxy";
import { useAppSelector } from "../../../app/hooks";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import classes from "../../../assets/styles/Task.module.css";
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as StatusesConsts from "../../../status-consts";
import { useIntl } from 'react-intl';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import downloadIcon from "../../../assets/images/icons/download.png";
import datevIcon from "../../../assets/images/icons/datev.png";
import datevCloseIcon from "../../../assets/images/icons/DatevCloseMark.png";
import datevCheckIcon from "../../../assets/images/icons/DatevCheckMark.png";
import datevTransferedIcon from "../../../assets/images/icons/DatevTransfered.png";
import CloseIcon from '@mui/icons-material/Close';
const getUserProfile = userServices.getUserProfile;

export interface User {
  id: string;
  name: string;
}
export interface CommentProps {
  taskDetails: {
    id: string;
    toBeTagedUsers: User[];
    comments: any;
    status: any
  };
  addCommentCall: (comment: {}, files: File[]) => void; // Update to take a string
  commentLoading: boolean;
  commentTxt: any;
  setCommentTxt: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  isFocused: any;
  setIsFocused: any;
  username: any;
  moveDocumentToDatevCall: any;
  movingToDatevLoading: any;
  commentFiles: any;
  setCommentFiles: any;
}

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#5D5FEF',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#5D5FEF',
    borderRadius: '8px',
    padding: '7px',
    fontFamily: 'DM Sans'
  },
}));

const suggestionsState = atom({
  key: 'BandSuggestionsVisible',
  default: false,
});

const Suggestions: React.FC<{
  field: React.RefObject<HTMLTextAreaElement>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  taskDetails: CommentProps['taskDetails'];
}> = ({ field, value, setValue, taskDetails }) => {
  const [hasSuggestions, setHasSuggestions] = useRecoilState(suggestionsState);

  const handleClick = useCallback((name: string) => {
    const newValue = value + name + ' ';
    setValue(newValue); // Update state to reflect new value
    setHasSuggestions(false);
    field.current?.focus(); // Focus immediately
  }, [value, setValue, setHasSuggestions, field]);

  const handleClickAway = useCallback(() => {
    setHasSuggestions(false);
  }, [setHasSuggestions]);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <>
        {hasSuggestions && (
          <Fade in={hasSuggestions} timeout={100} onEnter={(node) => node?.scrollTo(0, 0)}>
            <List component={Paper}
              sx={{
                width: '250px',
                maxHeight: '203px', // Use maxHeight instead of fixed height
                overflowY: 'auto',
                boxShadow: '0px 4px 8px 0px #00000040',
                '& .MuiListItemButton-root': {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                },
              }}>
              {taskDetails?.toBeTagedUsers?.filter((user) => user !== null).map((user) => (
                <ListItemButton key={user.id} onClick={() => handleClick(user.name)}>
                  <ProfileImage username={user.name} width='29px' height='29px' fontSize='12.5px' marginRight='6px' />
                  {user.name}
                </ListItemButton>
              ))}
            </List>

          </Fade>
        )}
      </>
    </ClickAwayListener>
  );
};

const SuggestionsField: React.FC<{
  taskDetails: CommentProps['taskDetails'];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  commentLoading: boolean;
  commentTxt: string;
  addTheComment: any;
  setCommentTxt: any;
  assigneesType: any;
  openAssigneesDropdown: any;
  setOpenAssigneesDropdown: any;
  setAssigneesType: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  viewers: any;
  setViewers: any;
  username: any;
  setIsFocused: any;
  isFocused: any;
  handleCancel: any;
  handleFileChange: any;
  commentFiles: any;
  setCommentFiles: any;
}> = (
  { taskDetails,
    value,
    setValue,
    commentLoading,
    commentTxt,
    setCommentTxt,
    addTheComment,
    assigneesType,
    setAssigneesType,
    openAssigneesDropdown,
    setOpenAssigneesDropdown,
    internalAssigneesList,
    externalAssigneesList,
    viewers,
    setViewers,
    isFocused,
    setIsFocused,
    username,
    handleCancel,
    handleFileChange,
    commentFiles,
    setCommentFiles
  }) => {
    const setHasSuggestions = useSetRecoilState(suggestionsState);
    const textFieldRef = useRef<HTMLTextAreaElement | null>(null);
    const suggestionsRef = useRef<HTMLDivElement | null>(null);

    const intl = useIntl();

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      setHasSuggestions(newValue.endsWith('@') && newValue.trim() !== '');
    }, [setHasSuggestions, setValue]);

    const handleFocus = useCallback(() => {
      setIsFocused(true);
      if (value.trim() === '') {
        setHasSuggestions(false);
      }
      if (textFieldRef.current) {
        const length = textFieldRef.current.value.length;
        textFieldRef.current.setSelectionRange(length, length);
      }
    }, []);

    const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const relatedTarget = event.relatedTarget as HTMLElement;
      const isClickInsideSuggestions = suggestionsRef.current?.contains(relatedTarget);


      if ((event.target as HTMLElement).classList.contains('greyButton')) {
        setHasSuggestions(false);
        setIsFocused(false);
      }
      if (!isClickInsideSuggestions) {
        setTimeout(() => {
          // setIsFocused(false);
        }, 300);
      }
    }, []);


    useEffect(() => {
      if (isFocused && textFieldRef.current) {
        textFieldRef.current.focus();
        const length = textFieldRef.current.value.length;
        textFieldRef.current.setSelectionRange(length, length);
      }
    }, [isFocused]);



    return (
      <Box position="relative" width="100%">
        <Box display="flex" alignItems="flex-start" width="100%" gap={2}>
          <ProfileImage username={username} width="38px" height="38px" fontSize="18px" flexShrink={0} />
          <TextField
            fullWidth
            inputRef={textFieldRef}
            disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
            multiline={isFocused}
            rows={isFocused ? 4 : 1}
            label={<Message id="task.add-comment" />}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}

            InputProps={{
              endAdornment: (
                isFocused ?
                  <HtmlTooltip
                    open={openAssigneesDropdown}
                    title={
                      <React.Fragment>
                        <Grid item container maxHeight='160px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                          <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                            <Grid item className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                              onClick={() => setAssigneesType('Internal')}>Internal</Grid>
                            <Grid item className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                              onClick={() => setAssigneesType('External')}>External</Grid>
                          </Grid>
                          <Grid item container direction="column" rowGap={1}>
                            {assigneesType === 'Internal' ? internalAssigneesList?.map((i: any, index: any) => (
                              <Grid item container alignItems='center' key={i.id} columnGap={1}
                                className={classes[viewers.find((v: string) => v === i.id) ? 'filter-selected' : 'filter-not-selected']}
                                justifyContent='space-between'
                                onMouseOver={(e) => {
                                  const target = e.target as HTMLElement;
                                  target.style.cursor = 'pointer';
                                }}
                                onClick={(e) => {
                                  // taskDetails.assigneeId = i.id
                                  // forceUpdate();
                                  console.log("clickeddd", i.id)
                                  if (viewers.find((v: string) => v === i.id)) setViewers(viewers.filter((v: string) => v !== i.id))
                                  else setViewers([...viewers, i.id])
                                  // setOpenAssigneesDropdown(false);
                                }}
                              >
                                <Grid item>{i.name}</Grid>
                                <Grid className='bordered-box' bgcolor={viewers.find((v: string) => v === i.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                              </Grid>
                            )) :
                              externalAssigneesList?.map((i: any) => (
                                <Grid item container alignItems='center' justifyContent='space-between' key={i.id} columnGap={1} className={classes[viewers.find((v: string) => v === i.id) ? 'filter-selected' : 'filter-not-selected']}
                                  onMouseOver={(e) => {
                                    const target = e.target as HTMLElement;
                                    target.style.cursor = 'pointer';
                                  }}
                                  onClick={(e) => {
                                    // taskDetails.assigneeId = i.id
                                    // forceUpdate();
                                    if (viewers.find((v: string) => v === i.id)) setViewers(viewers.filter((v: string) => v !== i.id))
                                    else setViewers([...viewers, i.id])
                                    //setOpenAssigneesDropdown(false);
                                  }}
                                >
                                  <Grid item>{i.name}</Grid>
                                  <Grid className='bordered-box' bgcolor={viewers.find((v: string) => v === i.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={viewers.find((v: string) => v === i.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    <InputAdornment position="end" style={{ width: '300px', display: 'flex', alignItems: 'flex-end', height: '20px', marginTop: '45px' }}>

                      <Grid item container direction='row' justifyContent='flex-end' className="bold labels neutral-3" alignItems='center' columnGap={0.2}
                      >
                        <ArrowTooltip title={intl.formatMessage({ id: 'document.you-can-upload' })} placement='top' >
                          <Grid item width='20px' container justifyContent='flex-end' alignItems='center' >
                            <input
                              type="file"
                              onChange={handleFileChange}
                              disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}
                              multiple
                              style={{ display: 'none' }} // Hide the default input
                              id="comment-file-upload" // Use an ID for the label
                            />
                            <label htmlFor="comment-file-upload">
                              <AttachFileIcon htmlColor="#93919A" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} />
                            </label>
                          </Grid>
                        </ArrowTooltip>
                        <PeopleAltOutlinedIcon htmlColor="#93919A" style={{ cursor: 'pointer' }} onClick={() => setOpenAssigneesDropdown(!openAssigneesDropdown)} />
                        <span style={{ paddingTop: '5px' }}>Visibile To : {viewers?.length === 0 ? "All" : "Selected"}</span>
                      </Grid>
                    </InputAdornment>
                  </HtmlTooltip>
                  : ""
              ),
            }}
            InputLabelProps={{
              shrink: Boolean(value) || isFocused,
            }}
            sx={{
              flexGrow: 1,
              // marginBottom: value ? '0px' : '45px',
              marginBottom: isFocused ? '0px' : '45px',
              '& .MuiOutlinedInput-root': {
                height: isFocused ? 'auto' : '38px',
                '& .MuiInputBase-input': {
                  padding: isFocused ? 'undefined' : '10px 14px',
                },
                '&.Mui-focused fieldset': {
                  borderColor: isFocused ? 'primary.main' : 'rgba(0, 0, 0, 0.23)',
                },
              },
              '& .MuiInputLabel-root': {
                top: isFocused || value ? 0 : '50%',
                transform: isFocused || value ? 'translate(14px, -50%) scale(0.75)' : 'translate(14px, -50%) scale(1)',
                transition: 'top 0.3s, transform 0.3s',
              },
            }}
          />
        </Box>
        {isFocused && (
          <Box position="absolute" left='80px' top="calc(100% - 50px)" zIndex={1} ref={suggestionsRef}>
            <Suggestions field={textFieldRef} value={value} setValue={setValue} taskDetails={taskDetails} />
          </Box>
        )}


        <Grid item container direction='column' rowGap={1} paddingLeft='51px'  paddingRight='5px' maxHeight='160px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} marginTop={2}>
          {commentFiles?.map((i: any, index: any) => (
            <Grid item container direction='row' key={index}
              padding={1.2} xs={9} style={{
                border: '1px solid #C3C2C7',
                borderRadius: '8px'
              }}
              alignItems='center'
              justifyContent='space-between'
            // position='relative'
            // columnSpacing={2}
            // margin={0.5}
            >
              <Grid item container className='bold' xs={8} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
              <Grid item container xs={4} justifyContent='flex-end' >
                <Grid item container direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'
                  onClick={() => {
                    if (index > -1 && index < commentFiles.length) {
                      const tmp = commentFiles.filter((item: any, ind: any) => ind !== index);
                      setCommentFiles(tmp)
                    }
                    console.log("commentfiles", commentFiles)
                  }}>

                  <CloseIcon htmlColor='black' style={{ width: "19px", height: "19px", marginTop: '3px', cursor: 'pointer' }} />

                </Grid>
              </Grid>
            </Grid>
          ))
          }
        </Grid>

        {/* 
        <Grid item container xs={12} alignItems='center' direction='column' maxHeight='60px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} >
          {commentFiles?.map((i: any, index: any) => (
            <Grid item container direction='row' className='black' justifyContent='flex-end' columnGap={0.5}>
              <Grid item>{i.name}</Grid>
              <Grid item
                onClick={() => {
                  if (index > -1 && index < commentFiles.length) {
                    const tmp = commentFiles.filter((item: any, ind: any) => ind !== index);
                    setCommentFiles(tmp)
                  }
                  console.log("commentfiles", commentFiles)
                }}
              ><CloseIcon htmlColor='black' style={{ width: "19px", height: "19px", marginTop: '3px', cursor: 'pointer' }} /></Grid>
            </Grid>

          ))}
        </Grid> */}

        {isFocused && (
          <Grid item container direction='row' marginTop={3} marginBottom={2} columnGap={1} justifyContent='flex-start' marginLeft={7}>
            <Grid item width='80px' alignSelf='flex-end'>
              <button className='blueButton'
                disabled={commentLoading || (!commentTxt && commentFiles?.length === 0)}
                onClick={() => addTheComment()}>
                {commentLoading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : <Message id="button.save" className='Field-input font-weight-700 labelsLinks' />}
              </button>
            </Grid>
            <Grid item width='80px'>
              <button className='greyButton' onClick={() => handleCancel()}>Cancel</button>
            </Grid>
          </Grid>
        )}

      </Box>
    );
  };

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    color: 'black',
  },
}));

const Messages = (props: CommentProps) => {
  const {
    taskDetails,
    addCommentCall,
    commentLoading,
    commentTxt,
    setCommentTxt,
    internalAssigneesList,
    externalAssigneesList,
    isFocused,
    setIsFocused, username,
    moveDocumentToDatevCall,
    movingToDatevLoading,
    commentFiles,
    setCommentFiles } = props;

  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('Internal');
  const [viewers, setViewers] = useState([]);

  const [transferDatevHover, setTransferDatevHover] = useState(false);
  const [docIndex, setDocIndex] = useState(-1);

  const token = useAppSelector((state) => state.userData.token);

  const addTheComment = () => {
    let mentions: string[] = [];
    // if (textFieldRef.current) {
    // let comment = textFieldRef.current.value;
    // let commentTmp = localStorage.getItem('comment');
    taskDetails?.toBeTagedUsers?.filter((c: any) => c !== null)?.map((u: any) => {
      if (commentTxt?.includes("@")) {
        if (commentTxt?.includes(u.name))
          mentions.push(u.id)
      }
    })
    // }

    let commentObj = {
      taskId: taskDetails.id,
      content: commentTxt,
      tags: mentions,
      visibility: viewers.length === 0 ? 1 : 2,
      viewers: viewers,
    }

    // console.log("commentObj", commentObj);

    addCommentCall(commentObj, commentFiles);
    // handleCancel();
  };
  const handleCancel = () => {
    setCommentTxt('');
    setViewers([]);
    setIsFocused(false);
    setCommentFiles([]);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    console.log("uploadedFiles", uploadedFiles)
    if (uploadedFiles) {
      const filesArray = Array.from(uploadedFiles);
      const combinedArray = [...filesArray, ...commentFiles];
      setCommentFiles(combinedArray);
      // uploadTaskDocumentCall(filesArray);
    }
  };

  const downloadFile = (uri: string, filename: string) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;

    // Append to the body to ensure it's in the document
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  return (
    <Grid item container direction='column' rowGap={2}>
      {/* {!addComment ?
      <Grid item width='150px' onClick={() => setAddComment(true)}><button className='blueButton'>Add Comment</button></Grid>
      :
      <></>} */}
      {/* {addComment ? */}
      <Grid item container direction='column' rowGap={1}>
        <RecoilRoot>
          <CssBaseline />
          <SuggestionsField
            taskDetails={taskDetails}
            value={commentTxt}
            setValue={setCommentTxt} // Pass the setter directly
            commentLoading={commentLoading}
            commentTxt={commentTxt}
            setCommentTxt={setCommentTxt}
            addTheComment={addTheComment}
            openAssigneesDropdown={openAssigneesDropdown}
            setOpenAssigneesDropdown={setOpenAssigneesDropdown}
            assigneesType={assigneesType}
            setAssigneesType={setAssigneesType}
            internalAssigneesList={internalAssigneesList}
            externalAssigneesList={externalAssigneesList}
            viewers={viewers}
            setViewers={setViewers}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            username={username}
            handleCancel={handleCancel}
            handleFileChange={handleFileChange}
            commentFiles={commentFiles}
            setCommentFiles={setCommentFiles}
          />
        </RecoilRoot>

        {/* {commentTxt && (
          <Grid item container direction='row' marginTop={3} marginBottom={2} columnGap={1} justifyContent='flex-start' marginLeft={7}>
            <Grid item width='80px' alignSelf='flex-end'>
              <button className='blueButton'
                disabled={commentLoading || !commentTxt}
                onClick={() => addTheComment()}>
                {commentLoading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : <Message id="button.save" className='Field-input font-weight-700 labelsLinks' />}
              </button>
            </Grid>
            <Grid item width='80px'>
              <button className='greyButton' onClick={handleCancel}>Cancel</button>
            </Grid>
          </Grid>
        )} */}


      </Grid>
      {/* :
      <></>
    } */}
      <Grid item container style={{ overflowY: 'auto', maxHeight: '300px' }} rowGap={2} >
        {taskDetails?.comments?.map((c: any, index: any) => (
          <Grid item container direction='column' key={c.id} rowGap={0.5} margin={1}>
            <Grid item container direction='row' columnGap={1.5} alignItems="flex-start">
              <ProfileImage username={c.creator} width='38px' height='38px' fontSize="18px" />
              <Grid item className='labels-extra-bold' sx={{}}>{c.creator}
                <br />
                <span className="labels neutral-3 normalFontWeight">{internalAssigneesList?.find((i: any) => i.id === c.creatorId) ? "Internal"
                  : externalAssigneesList?.find((i: any) => i.id === c.creatorId) ? "External"
                    : ""}
                </span>
              </Grid>
              <Grid item className='neutral-3'>{c.creationDate}</Grid>
              <Grid item className="lables neutral-3" container alignItems='center' width='200px'><PeopleAltOutlinedIcon htmlColor="#93919A" /> Visible To : {c.visibility === 1 ? "All" : "Selected"}</Grid>
            </Grid>
            <Grid item paddingLeft='49px'>{c.content}</Grid>
            <Grid item container direction='column' rowGap={1} paddingLeft='49px' paddingRight='5px' maxHeight='160px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} >
              {c.documents?.map((i: any, index: any) => (
                <Grid item container direction='row' key={index}
                  padding={1.2} xs={9} style={{
                    border: '1px solid #C3C2C7',
                    borderRadius: '8px'
                  }}
                  alignItems='center'
                  justifyContent='space-between'
                  position='relative'
                // columnSpacing={2}
                // margin={0.5}
                >
                  <Grid item container className='bold' xs={8} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
                  <Grid item container xs={4} justifyContent='flex-end' >
                    <Grid item container direction='row' columnGap={1} justifyContent='flex-end' alignItems='center'>

                      {!i.isDatev && localStorage.getItem('userType') === '1' ?
                        <Grid item container direction='row' bgcolor={movingToDatevLoading && index === docIndex ? "#CCF0EB" : ""}
                          style={{ border: "solid 1px #E6E6E8", borderRadius: '8px', cursor: 'pointer' }}
                          width={transferDatevHover && index === docIndex ? '200px' : '60px'}
                          justifyContent='center'
                          alignItems='center'
                          padding={0.3}
                          columnGap={1}
                          onMouseOver={() => {
                            setTransferDatevHover(true);
                            setDocIndex(index)
                          }}
                          onMouseLeave={() => {
                            if (!movingToDatevLoading) {
                              setTransferDatevHover(false);
                              setDocIndex(-1)
                            }
                          }
                          }
                          onClick={() => moveDocumentToDatevCall(i.id)}
                        >
                          <Grid item container direction='row'
                            width={transferDatevHover && index === docIndex ? '160px' : '15px'}
                            alignItems='center'
                            padding={0.2}
                            columnGap={0.5}
                            justifyContent='flex-end'>
                            {transferDatevHover && index === docIndex ?
                              <><span className="labels neutral-2 font-14" style={{ textAlign: 'center' }}>
                                {movingToDatevLoading ? "Aproving for Datev ..." : "Approve for DATEV"}</span>
                                <img src={datevCheckIcon} width='12px' height='17px' /></>
                              :
                              <img src={datevCloseIcon} width='12px' height='17px' />
                            }
                          </Grid>
                          <Grid item container alignItems='center' width='20px'>
                            <img src={datevIcon} width='17px' height='17px' />
                          </Grid>
                        </Grid>
                        : i.isDatev ? (
                          <Grid item style={{ position: 'absolute', right: '8%', bottom: '20%' }}><img src={datevTransferedIcon} /> </Grid>
                        )
                          :
                          <></>
                      }

                      <Grid item width='30px' onClick={() => {
                        downloadFile(i.uri, i.name);
                      }}>
                        <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: 'pointer' }} /> </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
              }
            </Grid>
          </Grid>
        ))}
      </Grid>

    </Grid>
  );
};

export default Messages;