import React, { useState, useEffect, useMemo } from 'react'
import { Box, Grid } from '@mui/material';
import * as TaskServices from '../../../services/task-services.proxy';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TaskDetailsContainer from '../../../components/Dashboard/ManageTasks/TaskDetailsContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDialogLoadingState, getLoadingState, setDialogLoading, setLoading } from '../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { getModifyValue, setModify } from '../../../app/slices/modifySlice';
import * as commentServices from "../../../services/comment-service.proxy";
import Unauthorized from '../../../sharedComponents/Unauthorized';
import { selectUserType } from '../../../app/slices/userDataSlice';
import CreateTask from '../../../components/Dashboard/ManageTasks/CreateTask';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as ReminderServices from '../../../services/reminder-services.proxy';

const TaskDetails = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getTaskById = TaskServices.getTaskById;
  const updateTask = TaskServices.updateTask;
  const getTaskInputData = TaskServices.getTaskInputData;
  const addComment = commentServices.addComment;
  const moveDocumentToDatev = commentServices.moveDocumentToDatev;
  const uploadTaskDocument = TaskServices.uploadTaskDocument;
  const deleteTaskDocument = TaskServices.deleteTaskDocument;
  const deleteTask = TaskServices.deleteTask
  const duplicateTask = TaskServices.duplicateTask;
  const addTask = TaskServices.addTask;
  const openOrCompleteTask = TaskServices.openOrCompleteTask;


  const postReminder = ReminderServices.postReminder;
  const inActiveReminder = ReminderServices.inActiveReminder;

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const taskId = query.get("id");

  //dispatched states
  const dialogLoading = useAppSelector(getDialogLoadingState);
  const modify = useAppSelector(getModifyValue);
  const userType = useAppSelector(selectUserType);

  //comment states
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentTxt, setCommentTxt] = useState<string>('');
  const [commentFiles, setCommentFiles] = useState<File[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [movingToDatevLoading, setMovingToDatevLoading] = useState(false);

  //data to be filled 
  const [taskDetails, setTaskDetails] = useState<any>();
  const [assigneesList, setAssigneesList] = useState<any>();
  const [internalAssigneesList, setInternalAssigneesList] = useState([]);
  const [externalAssigneesList, setExternalAssigneesList] = useState([]);
  const [clientsList, setClientsList] = useState<any>();
  const [typesList, setTypesList] = useState<any>();
  const [moduleList, setModuleList] = useState<any>();
  const [statusesList, setStatusesList] = useState<any>();
  const [historyCategoriesList, setHistoryCategoriesList] = useState<any>();
  const [recurringPeriodsList, setRecurringPeriodsList] = useState<any>();
  const [remindersList, setRemindersList] = useState<any>();

  //extra uninque loading states to not interfere with others
  const [initiallistLoading, setInitialListLoading] = useState(false);
  const [inputDataLoading, setInputDataLoading] = useState(false);

  const [unAuthorized, setUnAuthorized] = useState(false);

  //files
  const [files, setFiles] = useState<File[]>([]);
  const [fileLoading, setFileLoading] = useState('');

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedTaskToDelete, setselectedTaskToDelete] = useState<any>();
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
  const [taskObject, setTaskObject] = useState({
    title: "",
    dueDate: "",
    description: "",
    status: 1,
    type: 1,
    module: 1,
    clientId: localStorage.getItem('userType') === '1' ? "" : null,
    assigneeId: "",
    priority: 3,
    recurringPeriod: 0,
    note: ""
  })

  const [openCustomReminder, setOpenCustomReminder] = useState(false);


  const errorMsg = useAppSelector(getErrorMsg);
  const loading = useAppSelector(getLoadingState);
  const callStatus = useAppSelector(getCallStatus);

  const getTaskByIdCall = (clients: any) => {
    if (!taskId) return;
    setInitialListLoading(true);
    getTaskById(taskId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      }
      else {
        // let taskObj = {
        //   id: taskId,
        //   title: x.title,
        //   dueDate: moment(x.dueDate, 'M/D/YYYY').format('YYYY-MM-DD'),
        //   status: x.status,
        //   type: x.type,
        //   description: x.description,
        //   clientId: x.clientId,
        //   assigneeId: x.assigneeId,
        //   comments: x.comments,
        //   toBeTagedUsers: x.toBeTagedUsers,
        //   history: x.history
        // }
        // setTaskDetails(taskObj);
        setTaskDetails(x);
        // setTaskDetails((prevDetails: any) => ({
        //   ...prevDetails,
        //   dueDate: moment(x.dueDate, 'DD-MM-YYYY'),
        // }));
        setTaskDetails((prevDetails: any) => ({
          ...prevDetails,
          dueDate: moment(x.dueDate, 'MM/DD/YYYY'),
        }));
        setFiles(x.documents);
        if (userType === 1) {
          const clientTMs = clients?.find((i: any) => i.id === x.clientId)?.users;
          setExternalAssigneesList(clientTMs);
        }
      }
      setInitialListLoading(false);
    })

  }

  const getTaskInputDataCall = () => {
    setInputDataLoading(true);
    getTaskInputData().then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        // setAssigneesList(x.users);
        setInternalAssigneesList(x.internalUsers);
        if (userType === 2) setExternalAssigneesList(x.externalUsers);
        setClientsList(x.clients);
        setStatusesList(x.statuses);
        setTypesList(x.types);
        setModuleList(x.modules);
        setHistoryCategoriesList(x.historyCategory);
        setRecurringPeriodsList(x.recurringPeriods);
        getTaskByIdCall(x.clients);
        setRemindersList(x.reminderTypes);

        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      setInputDataLoading(false);
    })
  }

  const updateTaskCall = () => {
    dispatch(setLoading(true));
    let taskObj = { ...taskDetails, dueDate: moment(taskDetails.dueDate).format('YYYY-MM-DD') }

    // let taskObj = {
    //   id: taskId,
    //   title: taskDetails.title,
    //   dueDate: moment(taskDetails.dueDate).format('YYYY-MM-DD'),
    //   status: taskDetails.status,
    //   type: taskDetails.type,
    //   clientId: taskDetails.clientId,
    //   assigneeId: taskDetails.assigneeId,
    //   description: taskDetails.description,
    //   note: taskDetails.note,
    //   priority: taskDetails.priority,
    //   recurringPeriod:taskDetails.recurringPeriod

    // }
    updateTask(taskObj).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
          dispatch(setModify(!modify));
        }, 1500);

      }
      dispatch(setLoading(false));
    })
  }

  const openOrCompleteTaskCall = (taskId: string) => {
    dispatch(setLoading(true));
    openOrCompleteTask(taskId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
          dispatch(setModify(!modify));
        }, 1500);

      }
      dispatch(setLoading(false));
    })
  }


  const addCommentCall = (commentObj: {}, files: File[]) => {
    setCommentLoading(true);
    addComment(commentObj, files).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        setCommentTxt('');
        setCommentFiles([])
        setIsFocused(false);
        localStorage.removeItem('comment')
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
          dispatch(setModify(!modify));
        }, 500);

      }
      setCommentLoading(false)
    })
  }

  const moveDocumentToDatevCall = (documentId: any) => {
    setMovingToDatevLoading(true);
    moveDocumentToDatev(documentId, taskId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''))
        }, 1500);
      }
      else {
        dispatch(setCallStatus('Pass'));
        dispatch(setErrorMsg(""));
        dispatch(setCallStatus(""));
        dispatch(setActionCallFrom(''))
        dispatch(setModify(!modify));
      }
      setMovingToDatevLoading(false);
    })

  }

  const uploadTaskDocumentCall = () => {
    let newFiles = files?.filter((i) => !i.hasOwnProperty('id'))
    if (taskId) {
      setFileLoading('Upload');
      dispatch(setActionCallFrom('updateDocuments'))
      uploadTaskDocument(taskId, newFiles).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          dispatch(setCallStatus('Fail'));
          setTimeout(() => {
            dispatch(setErrorMsg(''));
            dispatch(setCallStatus(''));
            dispatch(setActionCallFrom(''))
          }, 4500)
        }
        else {
          dispatch(setCallStatus("Pass"));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
            dispatch(setCallStatus(""));
            dispatch(setActionCallFrom(''))
          }, 2000);

          getTaskByIdCall(clientsList);
        }
        setFileLoading('');
      })
    }
  }

  const deleteTaskDocumentCall = (documentId: any, item: any) => {
    const index = files.indexOf(item);

    if (documentId) {
      setFileLoading('Delete');
      deleteTaskDocument(documentId).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          dispatch(setCallStatus('Fail'));
          setTimeout(() => {
            dispatch(setErrorMsg(''));
            dispatch(setCallStatus(''))
          }, 4500)
        }
        else {
          if (index > -1) {
            let tmp = files;
            files.splice(index, 1);
            setFiles(tmp)
            console.log(tmp)

          }
          getTaskByIdCall(clientsList);
        }
        setFileLoading('');
      })
    }
    else {
      if (index > -1) {
        console.log("index to be removed", index)
        const filesArray = Array.from(files);
        filesArray.splice(index, 1);
        setFiles(filesArray);
      }
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      const filesArray = Array.from(uploadedFiles);
      const combinedArray = [...filesArray, ...files];
      setFiles(combinedArray);
      // uploadTaskDocumentCall(filesArray);
    }
  };

  const deleteTaskCall = () => {
    dispatch(setLoading(true));
    deleteTask(selectedTaskToDelete.id).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenDeleteDialog(false);
          dispatch(setModify(!modify))
        }, 1000)
        navigate('/tasks')
      }
      dispatch(setLoading(false));
    })
  }

  const addTaskCall = (taskObject: {}, buttonNb: any) => {
    dispatch(setLoading(true));
    addTask(taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenAddTaskDialog(false);
          if (buttonNb === 1) {
            setOpenAddTaskDialog(false);
            dispatch(setModify(!modify));
          }
          if (buttonNb === 2) navigate(`/task-details?id=${x}`)

        }, 1000);
      }
      dispatch(setLoading(false));
    })

  }

  const postReminderCall = (reminderObj: any, reminderId: any) => {
    if (reminderId) {

      if (reminderObj.typeId === 4) dispatch(setLoading(true))
      else dispatch(setDialogLoading(true))
      dispatch(setActionCallFrom('post reminder'))
      inActiveReminder(reminderId).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
          }, 4500);

        }
        else {
          if (reminderObj.typeId === 4) dispatch(setLoading(true))
          else dispatch(setDialogLoading(true))
          dispatch(setActionCallFrom('post reminder'))
          postReminder(reminderObj).then((x) => {
            if (x.ErrorMessage) {
              dispatch(setErrorMsg(x.ErrorMessage));
              setTimeout(() => {
                dispatch(setErrorMsg(""));
                dispatch(setActionCallFrom(''))
              }, 4500);

            }
            else {
              setOpenCustomReminder(false);
              dispatch(setModify(!modify));
              dispatch(setActionCallFrom(''))
            }
            dispatch(setLoading(false))
            dispatch(setDialogLoading(false))

          })


        }
      })


    }
    else {
      if (reminderObj.typeId === 4) dispatch(setLoading(true))
      else dispatch(setDialogLoading(true))
      dispatch(setActionCallFrom('post reminder'))
      postReminder(reminderObj).then((x) => {
        if (x.ErrorMessage) {
          dispatch(setErrorMsg(x.ErrorMessage));
          setTimeout(() => {
            dispatch(setErrorMsg(""));
            dispatch(setActionCallFrom(''))
          }, 4500);

        }
        else {
          dispatch(setDialogLoading(false))
          setOpenCustomReminder(false);
          dispatch(setModify(!modify));
          dispatch(setActionCallFrom(''))
        }
        if (reminderObj.typeId === 4) dispatch(setLoading(false))
        else dispatch(setDialogLoading(false))

      })
    }

  }

  const inActiveReminderCall = (reminderId: string) => {
    dispatch(setDialogLoading(true))
    inActiveReminder(reminderId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        dispatch(setModify(!modify));

      }
      dispatch(setDialogLoading(false))
    })

  }

  useEffect(() => {
    getTaskInputDataCall();
  }, [taskId, modify]);

  return (
    <Grid item container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialogLoading || initiallistLoading || inputDataLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {unAuthorized ?
        <Unauthorized navigateTo='/tasks' />
        :
        // statusesList?.length > 0 && assigneesList?.length > 0 && taskDetails?.status && taskDetails?.assigneeId ?
        statusesList?.length > 0 && taskDetails?.status ?
          <TaskDetailsContainer
            taskDetails={taskDetails}
            assigneesList={assigneesList}
            internalAssigneesList={internalAssigneesList}
            externalAssigneesList={externalAssigneesList}
            clientsList={clientsList}
            statusesList={statusesList}
            typesList={typesList}
            moduleList={moduleList}
            historyCategoriesList={historyCategoriesList}
            updateTaskCall={updateTaskCall}
            addCommentCall={addCommentCall}
            moveDocumentToDatevCall={moveDocumentToDatevCall}
            movingToDatevLoading={movingToDatevLoading}
            commentLoading={commentLoading}
            commentTxt={commentTxt}
            setCommentTxt={setCommentTxt}
            commentFiles={commentFiles}
            setCommentFiles={setCommentFiles}
            handleFileChange={handleFileChange}
            fileLoading={fileLoading}
            deleteTaskDocumentCall={deleteTaskDocumentCall}
            uploadTaskDocumentCall={uploadTaskDocumentCall}
            files={files}
            recurringPeriodsList={recurringPeriodsList}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            taskObject={taskObject}
            setTaskObject={setTaskObject}
            open={openAddTaskDialog}
            setOpen={setOpenAddTaskDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            setselectedTaskToDelete={setselectedTaskToDelete}
            openOrCompleteTaskCall={openOrCompleteTaskCall}
            remindersList={remindersList}
            postReminderCall={postReminderCall}
            inActiveReminderCall={inActiveReminderCall}
            setOpenCustomReminder={setOpenCustomReminder}
            openCustomReminder={openCustomReminder}
          />
          :
          <></>
      }

      <CreateTask
        loading={loading}
        open={openAddTaskDialog}
        setOpen={setOpenAddTaskDialog}
        callStatus={callStatus}
        errorMsg={errorMsg}
        confirmAction={addTaskCall}
        assigneesList={assigneesList}
        clientsList={clientsList}
        statusesList={statusesList}
        typesList={typesList}
        moduleList={moduleList}
        recurringPeriodsList={recurringPeriodsList}
        internalAssigneesList={internalAssigneesList}
        externalAssigneesList={externalAssigneesList}
        setExternalAssigneesList={setExternalAssigneesList}
        taskObject={taskObject}
        setTaskObject={setTaskObject}
      />

      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        loading={loading}
        callStatus={callStatus}
        errorMsg={errorMsg}
        object={selectedTaskToDelete}
        confirmAction={deleteTaskCall}
        type='task'
      />
    </Grid>
  )
}

export default TaskDetails