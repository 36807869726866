/* eslint-disable react-hooks/rules-of-hooks */
import * as apiConst from "../taxmation-consts";
import axios from "axios";

const GET_USER_PROFILE = apiConst.GET_USER_PROFILE
export const getUserProfile = (token: string) => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_USER_PROFILE}`,
            {
                withCredentials: true,  // Include cookies
                // headers: {
                //     'Content-Type': 'application/json',
                // }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_USER_DATA = apiConst.GET_USER_DATA
export const getUserData = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_USER_DATA}`,
            {
                withCredentials: true,  // Include cookies
                // headers: {
                //     'Content-Type': 'application/json',
                // }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const UPDATE_USER_PROFILE = apiConst.UPDATE_USER_PROFILE
export const updateUserProfile = (token: string, userData: object) => {
    return axios
        .put(`${process.env.REACT_APP_URL + UPDATE_USER_PROFILE}`, userData,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    // Include any additional headers as required
                    'Content-Type': 'application/json',
                    // Add Authorization header if necessary
                    // 'Authorization': `Bearer ${token}`,
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const GET_TM_USER_LIST = apiConst.GET_TM_USER_LIST;
export const getTMUsersList = (listData : {}) => {
    return axios
    .post(`${process.env.REACT_APP_URL + GET_TM_USER_LIST }`,
        listData,
        {
            withCredentials: true,
        }
    )
    .then((response) => {
        return response.data
    })
    .catch((error) => {
        return error.response.data
    })

}

const UPDATE_TM_ROLE = apiConst.UPDATE_TM_ROLE
export const updateTMRole = (userData: object) => {
    return axios
        .put(`${process.env.REACT_APP_URL + UPDATE_TM_ROLE}`, userData,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}


const DELETE_USER = apiConst.DELETE_USER;
export const deleteUser = (userId: string) => {
    return axios
        .delete(`${process.env.REACT_APP_URL + DELETE_USER}?userId=${userId}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const USER_MODULE = apiConst.USER_MODULE;
export const getUserModule = (userId: string) => {
    return axios
        .get(`${process.env.REACT_APP_URL + USER_MODULE}?userId=${userId}`,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

export const updateUserModule = (userId: string, modules:any) => {
    return axios
        .put(`${process.env.REACT_APP_URL + USER_MODULE}?userId=${userId}`, modules,
            {
                withCredentials: true,  // Include cookies
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}