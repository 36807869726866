import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Backdrop, Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../../assets/styles/User.module.css";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import Message from '../../../../sharedComponents/ui/Message';
import userAccessIcon from "../../../../assets/images/icons/userAccessIcon.png";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import * as clientServices from "../../../../services/client-services.proxy";
import * as userServices from "../../../../services/user-services.proxy";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getLoadingState, setLoading } from '../../../../app/slices/loadingSlice';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../../app/slices/apiCallSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const updateClientModule = clientServices.updateClientModule;
const updateUserModule = userServices.updateUserModule;


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        borderRadius: '10px',
        padding: 0,
        color: 'black',
    },
}));

export interface DialogProps {
    openConfigureAccessDialog: boolean;
    setOpenConfigureAccessDialog: CallableFunction;
    user: any;
    modules: any;
    taxAdvisorClients: any;
    type: any;
    userDataLoading: any;
    setTaxAdvisorClients: any;


}

const ConfigureAccess = (props: DialogProps) => {

    const { openConfigureAccessDialog, setOpenConfigureAccessDialog, user, modules, taxAdvisorClients, type, userDataLoading, setTaxAdvisorClients } = props;

    const [open, setOpen] = useState(false);
    const [openComapniesTooltip, setOpenComapniesTooltip] = useState(false);

    const dispatch = useAppDispatch();

    //store states
    const loading = useAppSelector(getLoadingState);
    const errorMsg = useAppSelector(getErrorMsg);
    const callStatus = useAppSelector(getCallStatus);
    const actionCallFrom = useAppSelector(getActionCallFrom);



    const [, updateState] = React.useState({});
    // Create a memoized callback to force re-render
    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);

    const handleClickOpen = () => {
        setOpenConfigureAccessDialog(true);
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
        setOpenConfigureAccessDialog(false);
    };

    useEffect(() => {
        if (openConfigureAccessDialog) {
            handleClickOpen();
        }
        else {
            setOpen(false);
        }
    }, [openConfigureAccessDialog]);

    const updateClientModuleCall = () => {
        dispatch(setLoading(true));
        dispatch(setActionCallFrom('update client'))
        updateClientModule(user.id, user.modules.map((i: any) => i.id)).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus("Fail"));
                setTimeout(() => {
                    dispatch(setErrorMsg(""));
                    dispatch(setCallStatus(""));
                }, 4500);
            } else {
                dispatch(setCallStatus("Pass"));
                setTimeout(() => {
                    dispatch(setErrorMsg(""));
                    dispatch(setCallStatus(""));
                    dispatch(setActionCallFrom(''))
                    handleClose();
                }, 1000);
            }
            dispatch(setLoading(false));
        });
    };

    const updateUserModuleCall = () => {
        dispatch(setLoading(true));
        dispatch(setActionCallFrom('update tm'))
        const updateObject = {
            accessModules:
                localStorage.getItem('userType') === '1' ?
                    user.modules.map((module: { modules: any[]; }) => ({
                        ...module,
                        modules: module.modules.map((mod: { id: any; }) => mod.id)  // Extracting only the 'id' from each module
                    })) : null,
            modules: localStorage.getItem('userType') === '2' ? user.modules.map((mod: { id: any; }) => mod.id) : null
        };

        updateUserModule(user.id, updateObject).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus("Fail"));
                setTimeout(() => {
                    dispatch(setErrorMsg(""));
                    dispatch(setCallStatus(""));
                }, 4500);
            } else {
                dispatch(setCallStatus("Pass"));
                setTimeout(() => {
                    dispatch(setErrorMsg(""));
                    dispatch(setCallStatus(""));
                    dispatch(setActionCallFrom(''))
                    handleClose();
                }, 1000);
            }
            dispatch(setLoading(false));
        });
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ sx: { borderRadius: "15px", width: '60%' } }}

        >
            <Grid item container direction='column' padding={3} rowGap={1}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={userDataLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item container className='black labels bold' alignItems='center' justifyContent='space-between'>
                    Configure Access for {user?.email}
                    <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}><CloseIcon /></span>
                </Grid>
                <Grid item className='greyLine' width='100%'></Grid>

                {type === 'Update Client' && user ?
                    <>
                        <Grid item container className='textfield' columnGap={1} rowGap={1} style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setOpenComapniesTooltip(!openComapniesTooltip)
                            }
                            }
                        >
                            {user.modules?.map((c: any) => (
                                <Grid item key={c.id} className={classes['roleBox']}>
                                    {modules?.find((m: any) => m.id === c.id)?.name}
                                </Grid>
                            ))}
                            <Grid item >
                                <HtmlTooltip
                                    // open={openComapniesTooltip && lineIndex === index}
                                    title={
                                        <React.Fragment>
                                            <Grid item container rowGap={1} maxHeight='290px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                                <Grid item container maxHeight='170px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={1}>
                                                    {modules?.map((i: any, index: number) => (
                                                        <Grid item container style={{ cursor: 'pointer' }} alignItems='center' key={i.id} columnGap={2} rowGap={1}
                                                            justifyContent='space-between'
                                                            onClick={(e) => {
                                                                if (!user.modules?.find((x: any) => x.id === i.id)) {
                                                                    const updatedModules = [...user.modules, i];
                                                                    user.modules = updatedModules;
                                                                    forceUpdate();
                                                                }
                                                                else {
                                                                    const updatedModules = user.modules?.filter((m: any) => m.id !== i.id)
                                                                    user.modules = updatedModules;
                                                                    forceUpdate();
                                                                }
                                                            }

                                                            }
                                                        >
                                                            <Grid item className='font-14 font-weight-400 '>{i.name}</Grid>
                                                            <Grid className='bordered-box'
                                                                bgcolor={user.modules?.find((m: any) => m.id === i.id) ? '#CCF0EB' : '#fff'}>
                                                                {
                                                                    user.modules?.find((m: any) => m.id === i.id) ?
                                                                        <CheckIcon fontSize='small' htmlColor='#00B69B' />
                                                                        :
                                                                        <></>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <Grid item container xs={12} alignItems='center' columnGap={1} style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        user.modules = [];
                                                        forceUpdate();
                                                    }}>
                                                    <RestartAltIcon /> Reset
                                                </Grid>
                                                {/* <Grid item container direction='row' justifyContent='flex-end' marginTop={1} marginBottom={2} columnSpacing={1}>
                       <Grid item xs={6}
                         onClick={() => {
                           setOpenComapniesTooltip(false)

                         }}>
                         <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                       </Grid>
                       <Grid item xs={6}>
                         <button
                           className='blueButton'
                           disabled={val.accessModules?.length === 0}
                           onClick={() => setOpenComapniesTooltip(false)}
                         >
                           Ok
                         </button>
                       </Grid>
                     </Grid> */}


                                            </Grid>
                                        </React.Fragment>
                                    }
                                >
                                    <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                        {/* <Grid item
                            className='blueButton'
                            onClick={() => {
                                updateClientModuleCall()

                            }}> Update </Grid> */}
                        <Grid item >
                            <button
                                disabled={loading && actionCallFrom === 'update client'}
                                className={actionCallFrom === 'update client' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'greenAccessButton' : 'greenAccessButton'}
                                onClick={() => {
                                    updateClientModuleCall()
                                }
                                }
                            >
                                {!loading ?
                                    <img src={userAccessIcon} />
                                    :
                                    <></>
                                }
                                {loading && actionCallFrom === 'update client' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                    : callStatus === 'Pass' && actionCallFrom === 'update client' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                        : callStatus === 'Fail' && actionCallFrom === 'update client' ? "Failed"
                                            : "Update Access Configuration"}
                            </button>
                        </Grid>
                    </>

                    :
                    <Grid item container direction='column' rowGap={2}>

                        {type === 'Update TM' && user ?

                            <Grid item container xs={12} direction='column' rowGap={1} marginTop={2}>
                                <Grid item container className='textfield' columnGap={1} rowGap={1} style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setOpenComapniesTooltip(!openComapniesTooltip)
                                    }
                                    }
                                >
                                    {user?.modules?.map((c: any) => (
                                        <Grid item key={c.id} className={classes['roleBox']}>
                                            {localStorage.getItem('userType') === '1' ? taxAdvisorClients?.find((m: any) => m.id === c.clientId)?.name :
                                                //  modules.find((m:any) => m.id === c)?.name
                                                c.name
                                            }
                                        </Grid>
                                    ))}

                                    <Grid item>
                                        <HtmlTooltip
                                            // open={openComapniesTooltip && lineIndex === index}
                                            title={
                                                <React.Fragment>
                                                    <Grid item container rowGap={1} maxHeight='290px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                                                        <Grid item container maxHeight='170px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={1}>
                                                            {(localStorage.getItem('userType') === '1' ? taxAdvisorClients : modules)?.map((i: any, index: number) => (
                                                                <Grid item container style={{ cursor: 'pointer' }} alignItems='center' key={i.id} columnGap={2} rowGap={1}
                                                                    justifyContent='space-between'

                                                                    onClick={(e) => {
                                                                        if (localStorage.getItem('userType') === '1') {
                                                                            if (!user.modules?.find((x: { clientId: any; }) => x.clientId === i.id)) {
                                                                                const updatedAccess = [...user.modules, { clientId: i.id, name: i.name, modules: [] }];
                                                                                //user.accessModules = updatedAccess;
                                                                                user.modules = updatedAccess;
                                                                                forceUpdate();
                                                                                // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                                                            }
                                                                            else {
                                                                                const updatedAccess = user.modules.filter((r: { clientId: any; }) => r.clientId !== i.id)
                                                                                user.modules = updatedAccess;
                                                                                forceUpdate();
                                                                            }
                                                                        }

                                                                        if (localStorage.getItem('userType') === '2') {
                                                                            if (!user.modules?.find((x: any) => x.id === i.id)) {
                                                                                const updatedModules = [...user.modules, i];
                                                                                user.modules = updatedModules;
                                                                                forceUpdate();
                                                                                // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                                                            }
                                                                            else {
                                                                                const updatedModules = user.modules.filter((m: any) => m.id !== i.id)
                                                                                user.modules = updatedModules;
                                                                                forceUpdate();
                                                                            }
                                                                        }

                                                                    }}
                                                                >
                                                                    <Grid item className='font-14 font-weight-400 '>{i.name}</Grid>
                                                                    <Grid className='bordered-box'
                                                                        bgcolor={
                                                                            ((localStorage.getItem('userType') === '1' && (type === 'Update TM' ? user?.modules : user?.accessModules)?.find((x: { clientId: any; }) => x.clientId === i.id)) || (localStorage.getItem('userType') === '2' && (type === 'Update TM' ? user?.modules : user?.accessModules)?.find((m: any) => m.id === i.id))) ? '#CCF0EB' : '#fff'}>
                                                                        {
                                                                            ((localStorage.getItem('userType') === '1' && (type === 'Update TM' ? user?.modules : user?.accessModules)?.find((x: { clientId: any; }) => x.clientId === i.id)) || (localStorage.getItem('userType') === '2' && (type === 'Update TM' ? user?.modules : user?.accessModules)?.find((m: any) => m.id === i.id))) ?
                                                                                <CheckIcon fontSize='small' htmlColor='#00B69B' />
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                        <Grid item container xs={12} alignItems='center' columnGap={1} style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                if (localStorage.getItem('userType') === '1') user.accessModules = [];
                                                                else user.modules = [];
                                                                forceUpdate();
                                                            }}>
                                                            <RestartAltIcon /> Reset
                                                        </Grid>



                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        >
                                            <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} />
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>


                            </Grid>
                            :
                            <></>}

                        {localStorage.getItem('userType') === '1' && (type === 'Update TM' ? user?.modules : user?.accessModules)?.map((cl: any) => (

                            <Grid item container direction='column' key={cl.clientId} border='1px solid #CCF0EB' borderRadius='8px'>
                                <Grid item container bgcolor='#CCF0EB' padding={1} justifyContent='space-between'>
                                    <Grid item container direction='row' alignItems='center' columnGap={1} xs={7}>
                                        <span className='green-bordered-box' style={{ width: '15px', height: '15px' }}> <CheckIcon fontSize='small' htmlColor='#00B69B' /></span>
                                        {taxAdvisorClients?.find((client: any) => client.id === cl.clientId)?.name}
                                    </Grid>
                                    <Grid item
                                        onClick={() => {
                                            setTaxAdvisorClients((prevClients: any) =>
                                                prevClients.map((client: any) =>
                                                    client.id === cl.clientId
                                                        ? { ...client, expand: !client.expand }
                                                        : client
                                                )
                                            );

                                            // const currentClient = taxAdvisorClients?.find((client: any) => client.id === cl.clientId)
                                            // let updatedView = []
                                            // setTaxAdvisorClients([])
                                        }}
                                    >
                                        {taxAdvisorClients?.find((client: any) => client.id === cl.clientId && client.expand) ?
                                            <KeyboardArrowDownIcon htmlColor='#c3c2c7' style={{ cursor: 'pointer' }} />
                                            :
                                            <KeyboardArrowUpIcon htmlColor='#c3c2c7' style={{ cursor: 'pointer' }} />
                                        }


                                    </Grid>
                                </Grid>
                                {taxAdvisorClients?.find((client: any) => client.id === cl.clientId && client.expand) ?
                                    <Grid item container direction='row' padding={2}>
                                        {taxAdvisorClients?.find((client: any) => client.id === cl.clientId && client.expand)?.modules?.map((i: any) => (
                                            <Grid item xs={4} container direction='row' columnGap={1}
                                                onClick={() => {
                                                    if (type === 'Update TM') {
                                                        if (localStorage.getItem('userType') === '1') {
                                                            if (cl.modules?.find((x: any) => x.id === i.id))
                                                                cl.modules = cl.modules.filter((cm: any) => cm.id !== i.id)
                                                            else
                                                                cl.modules = [...cl.modules, i]

                                                        }
                                                        if (localStorage.getItem('userType') === '2') {
                                                            if (user.modules?.find((x: any) => x.id === i.id))
                                                                user.modules = user.modules.filter((cm: any) => cm !== i.id)
                                                            else
                                                                user.modules = [...user.modules, i]

                                                        }

                                                    }
                                                    else {
                                                        if (cl.modules?.find((x: any) => x === i.id))
                                                            cl.modules = cl.modules.filter((cm: any) => cm !== i.id)
                                                        else
                                                            cl.modules = [...cl.modules, i.id]
                                                    }

                                                    forceUpdate();
                                                }}
                                            >
                                                <Grid className='bordered-box'
                                                    bgcolor={

                                                        (cl.modules?.find((md: any) => md === i.id)) || (type === 'Update TM' && cl.modules?.find((md: any) => md.id === i.id))
                                                            ? '#CCF0EB' : '#fff'}>
                                                    {(cl.modules?.find((md: any) => md === i.id)) || (type === 'Update TM' && cl.modules?.find((md: any) => md.id === i.id)) ?
                                                        <CheckIcon fontSize='small' htmlColor='#00B69B' />
                                                        :
                                                        <></>
                                                    }
                                                </Grid>
                                                <Grid item className='font-14 font-weight-400 '>{i.name}</Grid>

                                            </Grid>
                                        ))}
                                    </Grid>
                                    :
                                    <></>
                                }



                            </Grid>

                        ))}


                        <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1.5}>

                            {/* <Grid item xs={3}
                                onClick={() => handleClose()}
                            >
                                <button className='greyButton' onClick={() => handleClose()}><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
                            </Grid> */}
                            <Grid item xs={6} container >
                                <button
                                    disabled={loading && actionCallFrom === 'update tm'}
                                    className={actionCallFrom === 'update tm' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'greenAccessButton' : 'greenAccessButton'}
                                    onClick={() => {
                                        if (type === 'Update TM')
                                            updateUserModuleCall();
                                        else handleClose()
                                    }
                                    }
                                >
                                    {!loading ?
                                        <img src={userAccessIcon} />
                                        :
                                        <></>
                                    }
                                    {loading && actionCallFrom === 'update tm' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                        : callStatus === 'Pass' && actionCallFrom === 'update tm' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                            : callStatus === 'Fail' && actionCallFrom === 'update tm' ? "Failed"
                                                : type === 'Update TM' ? "Update Access Configuration" : "Apply Access Configuration"}
                                </button>

                            </Grid>
                        </Grid>
                    </Grid>
                }


            </Grid>
        </Dialog >
    )
}

export default ConfigureAccess