import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@mui/material';
import Message from '../../../sharedComponents/ui/Message';
import classes from "../../../assets/styles/Task.module.css";
import downloadIcon from "../../../assets/images/icons/download.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import deleteIcon from "../../../assets/images/icons/delete.png";
import { useAppSelector } from '../../../app/hooks';
import { getActionCallFrom, getCallStatus, getErrorMsg } from '../../../app/slices/apiCallSlice';
import moment from "moment";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { borderRadius, fontFamily } from '@mui/system';
import { useIntl } from 'react-intl';
import * as StatusesConsts from "../../../status-consts";

export interface propsInterface {
    handleFileChange: any;
    files: any,
    fileLoading: any;
    taskDetails: any;
    deleteTaskDocumentCall: any;
    uploadTaskDocumentCall: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: '#FEFEFF',
        padding: '10px',
        borderRadius: '10px'
    },
}));

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#5D5FEF',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#5D5FEF',
        borderRadius: '8px',
        padding: '7px',
        fontFamily: 'DM Sans'
    },
}));

const Attachments = (props: propsInterface) => {

    const { handleFileChange, files, fileLoading, taskDetails, deleteTaskDocumentCall, uploadTaskDocumentCall } = props;

    // stored states
    const errorMsg = useAppSelector(getErrorMsg);
    const callStatus = useAppSelector(getCallStatus);
    const actionCallFrom = useAppSelector(getActionCallFrom);
    const intl = useIntl();

    const downloadFile = (uri: string, filename: string) => {
        const link = document.createElement('a');
        link.href = uri;
        link.download = filename;

        // Append to the body to ensure it's in the document
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    return (

        <Grid container rowGap={1}>
            <Grid item container direction='row' justifyContent='space-between'>
                <Grid item container xs={6} className='black labels-extra-bold font-14' alignItems='center' columnGap={1}>
                    <AttachFileIcon htmlColor='#93919A' /><Message id="task.attachments" className="Field-input font-weight-700 font-14" />
                    <span className='nbCircle'>{files?.length}</span>
                </Grid>
                {errorMsg ?
                    <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                    :
                    <></>
                }
                {fileLoading ? <span style={{ color: fileLoading === 'Upload' ? '#1280BE' : '#EF4737', display: 'flex', alignItems: 'center', fontSize: '16px' }}> &nbsp;{fileLoading === 'Upload' ? "Uploading files" : "Deleting file"} &nbsp; &nbsp;<CircularProgress style={{ color: fileLoading === 'Upload' ? '#1280BE' : '#EF4737', width: '18px', height: '18px' }} /></span> : ""}
                <ArrowTooltip title={intl.formatMessage({ id: 'document.you-can-upload' })} placement='top' >
                    <Grid item width='155px' container justifyContent='flex-end' >
                        <input
                            type="file"
                            onChange={handleFileChange}
                            disabled={taskDetails.status === StatusesConsts.CLOSED_STATUS_ID || localStorage.getItem('userType') === '2' }
                            multiple
                            style={{ display: 'none' }} // Hide the default input
                            id="file-upload" // Use an ID for the label
                        />
                        <label htmlFor="file-upload" className={classes['addFileButton']} style={{ cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset' }}>
                            <Message id="task.add-attachments" className="Field-input font-weight-700 font-14" />
                        </label>
                    </Grid>
                </ArrowTooltip>
            </Grid>
            <Grid item container>
                <Grid item container direction='row' className='labels neutral-3' columnGap={2}>
                    <Grid item xs={5}><Message id="document.name" className='labels neutral-3' /></Grid>
                    <Grid item xs={1.5}><Message id="document.size" className='labels neutral-3' /></Grid>
                    <Grid item xs={3.5}><Message id="document.date-added" className='labels neutral-3' /></Grid>
                </Grid>
                <Grid item className='grey-line-full-width'></Grid>
            </Grid>
            <Grid item container rowGap={0.5} marginTop={1} style={{ overflowY: 'auto', maxHeight: '180px', minHeight: '50px' }} >
                {files?.map((i: any, index: any) => (
                    <Grid item container direction="row" key={i.id} columnGap={2} className={i.id ? 'black labels' : 'black bold labels'} alignItems='center'>
                        <Grid item container alignItems='center' xs={5} columnGap={0.4}><InsertDriveFileIcon fontSize='small' htmlColor='#93919A' />{i.name}</Grid>
                        <Grid item xs={1.5}>{i.size / 1000} KB</Grid>
                        <Grid item xs={3.5}> {i.creationDate ? i.creationDate : moment().format('DD/MM/YYYY h:mm:ss A')}</Grid>
                        <Grid item container direction='row' xs={1} alignItems='center' justifyContent='flex-end' columnGap={1}>
                            <Grid item
                                onClick={() => {
                                    downloadFile(i.uri, i.name);
                                }}
                            > <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: 'pointer' }} /> </Grid>
                            <Grid item>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Grid item container direction='column' rowGap={1} justifyContent='center' alignItems='center' >
                                                <Grid item container direction='row' alignItems='center' columnGap={0.5} style={{ cursor: taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID ? 'pointer' : 'unset' }}
                                                    onClick={() => {
                                                        if (taskDetails.status !== StatusesConsts.CLOSED_STATUS_ID)
                                                            deleteTaskDocumentCall(i.id, i);
                                                    }}>
                                                    <Grid item>
                                                    <img src={deleteIcon} width='20px' height='20px' />

                                                       // <DeleteOutlineIcon htmlColor='#EF4737' style={{ width: "19px", height: "19px", marginTop: '3px' }} />
                                                        </Grid>
                                                    <Grid item className='red labels bold'><Message id="ta.delete" className='red labels bold' /></Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                >
                                    <MoreVertIcon htmlColor='#93919A' style={{ cursor: 'pointer' }} />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>

                    </Grid>
                ))}
            </Grid>
            <Grid item container justifyContent='flex-end' marginTop={1}>
                <button
                    className={actionCallFrom === 'updateDocuments'? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton': 'blueButton'}
                    style={{ width: '200px' }}
                    onClick={() => {
                        uploadTaskDocumentCall();
                    }}
                    disabled={fileLoading || files?.filter((i: any) => !i.hasOwnProperty('id'))?.length === 0 || taskDetails.status === StatusesConsts.CLOSED_STATUS_ID}>
                    {fileLoading === 'Upload' ? <CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} />
                        : callStatus === 'Pass' && actionCallFrom === 'updateDocuments' ? <CheckCircleOutlinedIcon fontSize='small' />
                            : callStatus === 'Fail' && actionCallFrom === 'updateDocuments'? "Failed"
                                : intl.formatMessage({ id: 'document.update-documents' })}
                </button>
            </Grid>
        </Grid>
    )

}
export default Attachments