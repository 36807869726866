import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import * as historyCategoryConsts from "../../../history-categories-consts"
import * as statusesConsts from "../../../status-consts";
import classes from "../../../assets/styles/Task.module.css";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import downloadIcon from "../../../assets/images/icons/download.png";

export interface HistoryProps {
  taskDetails: any;
  historyCategoriesList: any

}

const History = (props: HistoryProps) => {
  const { taskDetails, historyCategoriesList } = props;

  const downloadFile = (uri: string, filename: string) => {
    const link = document.createElement('a');
    link.href = uri;
    link.download = filename;

    // Append to the body to ensure it's in the document
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  return (
    <Grid item container style={{ overflowY: 'auto', maxHeight: '300px' }} rowGap={3}>
      {taskDetails?.history?.map((i: any, index: any) => (
        <Grid item container direction='column' key={index} rowGap={1}>
          <Grid item container direction='row' columnGap={2}>
            {i.category === historyCategoryConsts.CREATION_CATEGORY_ID ?
              <Grid item container xs={9} direction='row' alignItems='center' columnGap={1}>
                <Grid item className='labels-extra-bold'>{i.creator} <span className='labels'>&nbsp;&nbsp;created the task on&nbsp;&nbsp; </span> </Grid>
                <Grid item className='neutral-3'>{i.creationDate}</Grid>
              </Grid>
              : i.category === historyCategoryConsts.DELETE_DOCUMENT_CATEGORY_ID || i.category === historyCategoryConsts.UPLOAD_DOCUMENT_CATEGORY_ID ?
                <Grid item container xs={9} direction='row' alignItems='center' justifyContent='space-between'>
                  <Grid item xs={10} className='labels-extra-bold'>{i.creator}
                    {i.category === historyCategoryConsts.DELETE_DOCUMENT_CATEGORY_ID ?
                      <span className='labels'>&nbsp;&nbsp;has deleted 1 document &nbsp;&nbsp; </span>
                      :
                      <span className='labels'>&nbsp;&nbsp;has uploaded {i.documents?.length} {i.documents?.length === 1 ? "document" : "documents"} &nbsp;&nbsp; </span>
                    }
                  </Grid>
                  <Grid item xs={2} className='neutral-3' textAlign='right'>{i.creationDate}</Grid>
                </Grid>
                :
                <Grid item container xs={9} direction='row' alignItems='center' justifyContent='space-between'>
                  <Grid item className='labels-extra-bold'>{i.creator} <span className='labels'>&nbsp;&nbsp;updated the&nbsp;&nbsp; </span> {historyCategoriesList?.find((c: any) => c.id === i.category)?.name} </Grid>
                  <Grid item className='neutral-3' textAlign='right'>{i.creationDate}</Grid>
                </Grid>
            }

          </Grid>
          <Grid item container rowGap={1.5}>
            {i.category !== historyCategoryConsts.CREATION_CATEGORY_ID ?
              i.category === historyCategoryConsts.STATUS_CATEGORY_ID ?
                <Grid item container direction='row' columnGap={2} alignItems='center'>
                  <Grid item
                    className={classes[
                      i.previousValue === statusesConsts.NEW_STATUS_NAME ? 'new-task' :
                        i.previousValue === statusesConsts.OPEN_STATUS_NAME ? 'open-task' :
                          i.previousValue === statusesConsts.OVERDUE_STATUS_NAME ? 'overdue-task' :
                            i.previousValue === statusesConsts.IN_PROGRESS_STATUS_NAME ? 'inprogress-task' :
                              i.previousValue === statusesConsts.WAITING_FOR_OTHERS_STATUS_NAME ? 'completed-task' :
                                i.previousValue === statusesConsts.CLOSED_STATUS_NAME ? 'closed-task' : ''
                    ]}
                  >
                    {i.previousValue}
                  </Grid>
                  <Grid item>to</Grid>
                  <Grid item
                    className={classes[
                      i.nextValue === statusesConsts.NEW_STATUS_NAME ? 'new-task' :
                        i.nextValue === statusesConsts.OPEN_STATUS_NAME ? 'open-task' :
                          i.nextValue === statusesConsts.OVERDUE_STATUS_NAME ? 'overdue-task' :
                            i.nextValue === statusesConsts.IN_PROGRESS_STATUS_NAME ? 'inprogress-task' :
                              i.nextValue === statusesConsts.WAITING_FOR_OTHERS_STATUS_NAME ? 'completed-task' :
                                i.nextValue === statusesConsts.CLOSED_STATUS_NAME ? 'closed-task' : ''
                    ]}
                  >
                    {i.nextValue}
                  </Grid>
                </Grid>
                : i.category === historyCategoryConsts.DELETE_DOCUMENT_CATEGORY_ID || i.category === historyCategoryConsts.UPLOAD_DOCUMENT_CATEGORY_ID ?
                  i.documents?.map((i: any, index: any) => (
                    <Grid item container direction='row' key={index}
                      padding={1.2} xs={9} style={{
                        border: '1px solid #C3C2C7',
                        borderRadius: '8px'
                      }}
                      alignItems='center'
                      justifyContent='space-between'
                    // columnSpacing={2}
                    // margin={0.5}
                    >
                      <Grid item container className='bold' xs={10} columnGap={2} alignItems='center'> <InsertDriveFileIcon fontSize='medium' htmlColor='#93919A' /> {i.name} <br />{i.size} KB</Grid>
                      <Grid item container xs={2} justifyContent='flex-end'
                        onClick={() => {
                          downloadFile(i.uri, i.name);
                        }}
                      > <img alt='icon' src={downloadIcon} width={17} height={17} style={{ cursor: 'pointer' }} /> </Grid>

                    </Grid>
                  ))

                  :

                  <Grid item> {i.previousValue}&nbsp;&nbsp;&nbsp; to &nbsp;&nbsp;&nbsp; {i.nextValue}</Grid>
              :
              ""
            }
          </Grid>
        </Grid>
      ))
      }
    </Grid >
  )
}

export default History