import React, { useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import * as taxAdvisorServices from "../../../services/tax-advisor-services.proxy";
import * as clientServices from "../../../services/client-services.proxy";
import { useNavigate } from 'react-router-dom';
import Message from '../../../sharedComponents/ui/Message';

const addTM = taxAdvisorServices.addTM;
const addTeamMembers = clientServices.addTeamMembers;
const inviteClients = taxAdvisorServices.inviteClient;

export interface UserProps {
  activeTab: any;
  addedTms: any;
  roles: any;
  setActiveSection: any;
  addedClients: any;
  taxAdvisorClients: any;
  modules: any;

}

const ReviewAndInvite = (props: UserProps) => {
  const { activeTab, addedTms, roles, setActiveSection, addedClients, taxAdvisorClients, modules } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);

  const roleCounts: { [key: number]: number } = {};
  const companyCounts: { [key: number]: number } = {};

  const users = activeTab === 'Tms' ? addedTms : addedClients;

  useEffect(() => {
    console.log("users", users);
    console.log("tax clients", taxAdvisorClients)
  })

  // Iterate through addedTms and update the counts
  users.forEach((item: any) => {
    item.roles.forEach((roleId: any) => {
      if (roleCounts[roleId]) {
        roleCounts[roleId]++;
      } else {
        roleCounts[roleId] = 1;
      }
    });
    if (localStorage.getItem('userType') === '1' && activeTab === 'Tms') {
      item.accessModules?.forEach((cl: any) => {
        if (companyCounts[cl.clientId]) {
          companyCounts[cl.clientId]++;
          console.log("companycount", companyCounts)
        }
        else {
          companyCounts[cl.clientId] = 1;

        }
      })
    }
  });

  // Create the new array with roleId and newCount
  const rolesAddition = roles?.map((role: any) => ({
    roleId: role.id,
    name: role.name,
    userCount: role.userCount,
    clientCount: role.clientCount,
    newCount: roleCounts[role.id] ?? 0
  }));

  //Create companiesAdditions array including new count
  const companyAdditions = taxAdvisorClients?.map((cl: any) => ({
    clientId: cl.id,
    name: cl.name,
    userCount: cl.userCount,
    newCount: companyCounts[cl.id] ?? 0
  }))


  const filteredRolesAddition = rolesAddition?.filter((role: any) => role.newCount !== 0);
  const filteredCompaniesAddition = companyAdditions?.filter((cl: any) => cl.newCount !== 0);

  const addTMSCall = () => {
    dispatch(setLoading(true));
    addTM(users).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          navigate('/users?type=1');
        }, 1500)
      }
      dispatch(setLoading(false));
    })
  }
  const addTeamMembersCall = () => {
    dispatch(setLoading(true));
    addTeamMembers(users).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          navigate('/users?type=1');
        }, 1500)
      }
      dispatch(setLoading(false));
    })
  }

  const addClientsCall = () => {
    dispatch(setLoading(true));
    inviteClients(users).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          navigate('/users?type=2');
        }, 1500)
      }
      dispatch(setLoading(false));
    })
  }

  return (
    <Grid item container direction='column' padding={4} rowGap={1} justifyContent='center'>
      <Grid item>{activeTab}</Grid>
      <Grid item container direction='column'>
        <Grid item className='black desktop-header-5'> <Message id="ta.review-and-invite" className="Field-input desktop-header-5  font-weight-400 " /> </Grid>
        <Grid item className='black labels bold'><Message id="text.review-and-finalize-invite" className="Field-input labels font-weight-400 " /></Grid>
      </Grid>
      <Grid item width='100p%' height='0.5px' bgcolor='#C3C2C7' marginTop={3} marginBottom={2}></Grid>

      {/* <Grid item container rowGap={1}> */}
      {/* <Grid item className='black labels labels-extra-bold'><Message id="text.review.user-details" className="Field-input labels labels-extra-bold " /></Grid>
        <Grid item container direction='row' className='labels neutral-2 labels-extra-bold' columnGap={2}>
          <Grid item xs={4}>  {activeTab === 'Tms' ? "Role "
            :
            <Message id="ta.level-of-access" className="Field-input labels font-weight-400 " />
          } </Grid>
          <Grid item xs={3}>  <Message id="login.email" className="Field-input labels font-weight-400 " /> </Grid>
        </Grid>
        {users.map((val: any, index: number) => (
          <Grid item container direction='row' key={val.id} columnGap={2} className='neutral-6'>
            <Grid item xs={4}>
              {val.roles.map((role: any, rolesIndex: number) => (
                <span>{roles?.find((r: { id: any; }) => r.id === role)?.name} {rolesIndex < val.roles.length - 1 ? " , " : ""}</span>
              ))}
            </Grid>
            <Grid item xs={3}>
              {val.email}
            </Grid>
          </Grid>
        ))} */}

      {users.map((val: any, index: number) => (
        <>
          <Grid item container direction='column' key={val.id} columnGap={2} className='neutral-6' rowGap={1.5}>
            <Grid item className='bold'>
              <span style={{ backgroundColor: '#E5F0FC', padding: '4px', borderRadius: '5px' }}>{val.email}</span>
            </Grid>
            <Grid item>
              <Message id="ta.review.role" className="Field-input labels labels-extra-bold neutral-2" />
              {val.roles.map((role: any, rolesIndex: number) => (
                <span>{roles?.find((r: { id: any; }) => r.id === role)?.name} {rolesIndex < val.roles.length - 1 ? " , " : ""}</span>
              ))}
            </Grid>
            {localStorage.getItem('userType') === '1' && activeTab === 'Tms' ?
              <>
                <Grid container direction='row'>
                  <Grid item xs={4}>
                    <Message id="ta.review.company" className="Field-input labels labels-extra-bold neutral-2 " />
                  </Grid>
                  <Grid item xs={4}><Message id="ta.review.modules" className="Field-input labels labels-extra-bold neutral-2 " /></Grid>
                </Grid>
                {localStorage.getItem('userType') === '1' && activeTab === 'Tms' ?
                  <Grid item>
                    {val.accessModules?.map((cl: any) => (
                      <Grid item container direction='row'>
                        <Grid item xs={4}>{cl.name}</Grid>
                        <Grid item xs={4}>{cl.modules?.map((m: any, mIndex: number) => (
                          <span>{modules?.find((r: { id: any; }) => r.id === m)?.name} {mIndex < cl.modules?.length - 1 ? " , " : ""}</span>
                        ))}</Grid>
                      </Grid>
                    ))}
                  </Grid>
                  :
                  <></>}
              </>
              :
              <Grid item>
                <Message id="ta.review.modules" className="Field-input labels labels-extra-bold neutral-2" />
                {val.modules?.map((m: any, mIndex: number) => (
                  <span>{modules?.find((r: { id: any; }) => r.id === m)?.name} {mIndex < val.modules?.length - 1 ? " , " : ""}</span>
                ))}
              </Grid>}

          </Grid>
          <Grid item width='100p%' height='0.5px' bgcolor='#C3C2C7' marginTop={1.5} marginBottom={1.5}></Grid>

        </>
      ))}
      {/* </Grid > */}
      {/* <Grid item className='greyLine' width='100%' marginTop={0.5} marginBottom={0.5}></Grid> */}
      <Grid container rowGap={1}>
        <Grid item className='black labels labels-extra-bold' ><Message id="text.review.role-additions" className="Field-input labels labels-extra-bold " /></Grid>
        <Grid item container direction='row' className='labels neutral-2 labels-extra-bold'>
          <Grid item xs={3}><Message id="ta.review.role" className="Field-input labels labels-extra-bold " /></Grid>
          <Grid item xs={4}><Message id="ta.review.new-users-invited" className="Field-input labels labels-extra-bold " /></Grid>
          <Grid item xs={3}><Message id="ta.review.existing-users" className="Field-input labels labels-extra-bold " /></Grid>
        </Grid>
        {filteredRolesAddition.map((val: any) => (
          <Grid item container direction='row' className='labels black' key={val.roleId}>
            <Grid item xs={3}>{val.name}</Grid>
            <Grid item xs={4}>{val.newCount}</Grid>
            <Grid item xs={3}> {activeTab === 'Tms' ? val.userCount : val.clientCount}</Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item width='100p%' height='0.5px' bgcolor='#C3C2C7' marginTop={0.5} marginBottom={0.5}></Grid>
      {localStorage.getItem('userType') === '1' && activeTab === 'Tms' ?
        <>
          <Grid container rowGap={1}>
            <Grid item className='black labels labels-extra-bold' ><Message id="text.review.comapny-additions" className="Field-input labels labels-extra-bold " /></Grid>
            <Grid item container direction='row' className='labels neutral-2 labels-extra-bold'>
              <Grid item xs={3}><Message id="ta.review.company" className="Field-input labels labels-extra-bold " /></Grid>
              <Grid item xs={4}><Message id="ta.review.new-users-invited" className="Field-input labels labels-extra-bold " /></Grid>
              <Grid item xs={3}><Message id="ta.review.existing-users" className="Field-input labels labels-extra-bold " /></Grid>
            </Grid>
            {filteredCompaniesAddition.map((val: any) => (
              <Grid item container direction='row' className='labels black' key={val.clientId}>
                <Grid item xs={3}>{val.name}</Grid>
                <Grid item xs={4}>{val.newCount}</Grid>
                <Grid item xs={3}> {val.userCount}</Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item width='100p%' height='0.5px' bgcolor='#C3C2C7' marginTop={1} marginBottom={0.5}></Grid>
        </>
        :
        <></>
      }

      {
        localStorage.getItem('userType') === '1' ?
          <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
            {errorMsg ?
              <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
              :
              <></>}

            <Grid item xs={2.5} >
              <button className='greyButton'
                onClick={() => setActiveSection('Add')}
              >Back</button>
            </Grid>
            <Grid item xs={2.5}>
              <button
                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                disabled={loading}
                onClick={() => {
                  if (activeTab === 'Tms') addTMSCall()
                  else addClientsCall();
                }
                }
              >
                {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Fail' ? "Failed"
                      : <Message id="button.invite" className="Field-input labelsLinks labels-extra-bold" />}
              </button>
            </Grid>
          </Grid>

          :

          <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
            {errorMsg ?
              <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
              :
              <></>}

            <Grid item xs={2.5} >
              <button className='greyButton'
                onClick={() => setActiveSection('Add')}
              ><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
            </Grid>
            <Grid item xs={2.5}>
              <button
                className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
                disabled={loading}
                onClick={() => {
                  addTeamMembersCall()

                }
                }
              >
                {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                  : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Fail' ? "Failed"
                      : <Message id="button.invite" className="Field-input labelsLinks labels-extra-bold" />}
              </button>
            </Grid>
          </Grid>
      }
    </Grid>

  )
}

export default ReviewAndInvite