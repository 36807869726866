import * as apiConst from "../taxmation-consts";
import axios from "axios";

const POST_REMINDER = apiConst.POST_REMINDER;
export const postReminder = (reminderObj: {}) => {
    return axios
        .post(`${process.env.REACT_APP_URL + POST_REMINDER}`, reminderObj,
            {
                withCredentials: true,  // Include cookies
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}

const IN_ACTIVE_REMINDER = apiConst.IN_ACTIVE_REMINDER;
export const inActiveReminder = (reminderId: string) => {
    return axios
        .get(`${process.env.REACT_APP_URL + IN_ACTIVE_REMINDER}?reminderId=${reminderId}`,
            {
                withCredentials: true,  // Include cookies
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}